import Modal from 'react-bootstrap/Modal';
import { createGroupTemplate, getGroupAvailableProductLists } from '../TemplateActions'
import { Button, Form} from "react-bootstrap";
import { useState, useEffect } from 'react';
import { success, error } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import moment from 'moment';
import InputGroup from 'react-bootstrap/InputGroup';
import { containsError } from '../../../services/ErrorResolver';
import { dayKeys, getTranslatedWeekday } from '../../../helpers/DateTime'

function CreateTemplateModal(props) {
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);

    const { groupId } = useParams();

    const [productLists, setProductLists] = useState([]);
  
    const [createFormValue, setCreateFormValue] = useState({
      listId: '',
      dayOfWeek: '',
      time: ''
    });

    const onCreateChange = (e) => {
      setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    };

    function resetModal() {
      setCreateFormValue({
        listId: '',
        dayOfWeek: '',
        time: ''
      });

      setValidated(false);
    };

    function getAvailableProductLists() {
      getGroupAvailableProductLists(groupId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.productLists && responseData.productLists.length < 1) {
          setProductLists([])

          return;
        }
      
        setProductLists(responseData.productLists);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }
  
    function handleCreateTemplate(e) {
      e.preventDefault();
      
      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }

      let timeLimit = moment(createFormValue.time, "HH:mm").local().toISOString();
  
      createGroupTemplate(groupId, createFormValue.listId, createFormValue.dayOfWeek, timeLimit).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;

          if (containsError(errors, "LIST_NOT_FOUND")) {
            error(t("error.LIST_NOT_FOUND"));
          }

          return;
        }
      
        success(t("success.CREATED_TEMPLATE"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleTimeClear(e) {
      setCreateFormValue({ ...createFormValue, time: '' });
    }

    useEffect(() => {
      getAvailableProductLists();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("page.create_schedule")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleCreateTemplate}>
            <Form.Group className="mb-3" controlId="formListId">
              <Form.Label className="text-center">
                {t("input.select_list")}
              </Form.Label>
              <Form.Select aria-label="Product list id" name="listId" onChange={onCreateChange} required>
                <option value="">{t("input.select_list")}</option>

                {productLists.length > 0 && productLists.map((productList) => {
                    return <option key={productList.id} value={productList.id}>{productList.name}{productList.description && " - " + productList.description}</option>
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">translated invalid max usage</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="dayOfWeek">
              <Form.Label className="text-center">
                {t("input.day_of_week")}
              </Form.Label>
              <Form.Select aria-label="Day of week" name="dayOfWeek" onChange={onCreateChange} required>
                <option value="">{t("input.day_of_week")}</option>

                {dayKeys.map((weekDay, index) => {
                  if (index > 0) {
                    return <option key={index} value={index}>{getTranslatedWeekday(t, index)}</option>
                  } else {
                    return;
                  }
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">translated invalid day of week</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTime">
              <Form.Label className="text-center">
              {t("input.time_limit")}
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="time" name="time" placeholder={t("input.time_limit")} onChange={onCreateChange} value={createFormValue.time} aria-describedby="date-clear-button" required/>

                <Button variant="secondary" id="time-clear-button" onClick={(e) => handleTimeClear(e)}>{t('action.clear')}</Button>

                <Form.Control.Feedback type="invalid">translated invalid time limit</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.create")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CreateTemplateModal;