import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function createProduct(groupId, listId, name, description, price) {
    return axios({
      method: 'post',
      url: API_URL + "groups/" + groupId + "/lists/" + listId + "/products",
      headers: authHeader(),
      data: {
        name, 
        description,
        price
      }
    })
}

export function updateProduct(groupId, listId, productId, price) {
  return axios({
    method: 'put',
    url: API_URL + "groups/" + groupId + "/lists/" + listId + "/products/" + productId,
    headers: authHeader(),
    data: {
      price
    }
  })
}

export function disableProduct(groupId, listId, productId) {
  return axios({
    method: 'delete',
    url: API_URL + "groups/" + groupId + "/lists/" + listId + "/products/" + productId,
    headers: authHeader()
  })
}