import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

function TokenListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="6">
                <div className="fw-bold">
                    {!props.token.expired &&
                    <Badge bg="success" style={{width: "1.6rem", fontSize: "0.7rem", paddingLeft: "0.3rem"}}>
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                    </Badge>}

                    {props.token.expired &&
                    <Badge bg="danger">
                        <FontAwesomeIcon icon={faXmark} size="lg" />
                    </Badge>}
                    {' '}

                    <span style={{lineBreak: "anywhere"}}>{props.token.token}{' '}</span>
                </div>
            </Col>
            <Col xs="4">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('token.times_used')}
                    </div>

                    {props.token.timesUsed}
                </div>
            </Col>
            <Col xs="1" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.token)}>{t('page.details')}</NavDropdown.Item>

                    {!props.token.expired &&
                        <NavDropdown.Item onClick={() => props.actions.disable(props.token)}>{t('action.disable')}</NavDropdown.Item>
                    }
                </NavDropdown>
                </Button>
            </Col>
            </Row>
    </ListGroup.Item>);
}

export default TokenListItem;