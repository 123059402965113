import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { parseWithDecimals } from '../../helpers/Number';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function OrderProductListItem(props) {

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="9">
                <h3><b>{props.product.name}</b></h3>
                <p>{props.product.description}</p>
            </Col>
            <Col xs="3" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onClick={() => props.actions.addProduct(props.product)}>
                    <FontAwesomeIcon icon={faPlus} size="lg" />
                </Button>
            </Col>
        </Row>
        <Row>
            <Col xs="12">
                <p style={{marginBottom: "0"}}>€ {parseWithDecimals(props.product.price, 2)}</p>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default OrderProductListItem;