import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupTemplates(groupId) {
    return axios.get(API_URL + "groups/" + groupId + "/templates", {
        headers: authHeader()
    });
}

export function createGroupTemplate(groupId, listId, dayOfWeek, time) {
  return axios({
    method: 'post',
    url: API_URL + "groups/" + groupId + "/templates",
    headers: authHeader(),
    data: {
      listId,
      dayOfWeek,
      time
    }
  })
}

export function deleteGroupTemplate(groupId, templateId) {
  return axios({
    method: 'delete',
    url: API_URL + "groups/" + groupId + "/templates/" + templateId,
    headers: authHeader()
  })
}

export function getGroupAvailableProductLists(groupId) {
  return axios.get(API_URL + "groups/" + groupId + "/available-lists", {
      headers: authHeader()
  });
}