import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';

import { formatDateTime } from '../../helpers/DateTime'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faCircleInfo, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function ExpiredScheduleListItem(props) {
    const { t } = useTranslation();

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="4">
                <div className="fw-bold">
                    <Badge bg="danger">
                        <FontAwesomeIcon icon={faStopwatch} size="lg" /> 
                    </Badge>
                    {' '}
                    <span style={{lineBreak: "anywhere"}}>{props.schedule.productListName}{' '}</span>
                </div>
            </Col>
            <Col xs="5">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('expired_at')}
                    </div>

                    {formatDateTime(props.schedule.dateTimeLimit)}
                </div>
            </Col>
            <Col xs="3" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onClick={() => props.actions.fulfill(props.schedule)}><FontAwesomeIcon icon={faCheck} /></Button>
                <Button variant="light" className="mt-1" onClick={() => props.actions.details(props.schedule)}><FontAwesomeIcon icon={faCircleInfo} /></Button>
                <Button variant="light" className="mt-1" onClick={() => props.actions.order(props.schedule)}><FontAwesomeIcon icon={faCartShopping} /></Button>
                <Button variant="light" className="mt-1" onClick={() => props.actions.cancel(props.schedule)}><FontAwesomeIcon icon={faBan} /></Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default ExpiredScheduleListItem;