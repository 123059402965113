import moment from 'moment';

export const dayKeys = ['', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export function formatDateTime(value) {
    // From utc to local
    return moment(value).format('DD-MM-YYYY HH:mm')
}

export function formatTime(value) {
    // From utc to local
    var day = moment().utc();
    var splitTime = value.split(/:/)
    day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0);

    return day.local().format('HH:mm')
}

export function getTranslatedWeekday(translation, dayOfWeek) {
    // 1 = Monday
    // 7 = Sunday

    return translation("weekdays." + dayKeys[dayOfWeek])
}