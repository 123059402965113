import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import { parseWithDecimals } from '../../helpers/Number';

import { useState } from 'react';

import { formatDateTime } from '../../helpers/DateTime'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function OrderListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col md="2">
                {props.order.orderFulfilled &&
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} size="lg" /> {t('status.success')}
                </Badge>}

                {props.order.orderCancelled &&
                <Badge bg="danger">
                    <FontAwesomeIcon icon={faXmark} size="lg" /> {t('status.cancelled')}
                </Badge>}

                {(!props.order.orderCancelled && !props.order.orderFulfilled) && 
                <Badge bg="warning">
                    <FontAwesomeIcon icon={faSpinner} size="lg" /> {t('status.pending')}
                </Badge>}
            </Col>
            <Col md="2" xs="5">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('product_list')}
                    </div>

                    {props.order.productListName}
                </div>
            </Col>
            <Col md="4" xs="5" className="d-none d-md-block">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('created')}
                    </div>

                    {formatDateTime(props.order.orderCreated)}
                </div>
            </Col>
            <Col md="2" xs="4">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('cost')}
                    </div>

                    € {parseWithDecimals(props.order.orderCost, 2)}
                </div>
            </Col>
            <Col md="2" xs="2" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.order)}>{t('page.details')}</NavDropdown.Item>

                    {!props.order.orderCancelled && 
                        <NavDropdown.Item onClick={() => props.actions.cancel(props.order)}>{t('action.cancel')}</NavDropdown.Item>
                    }
                </NavDropdown>
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default OrderListItem;