import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { parseWithDecimals } from '../../helpers/Number'

function UserListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="6">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {props.user.active &&
                        <Badge bg="success" style={{width: "1.6rem", fontSize: "0.7rem", paddingLeft: "0.3rem"}}>
                            <FontAwesomeIcon icon={faCheck} size="lg" />
                        </Badge>}

                        {!props.user.active &&
                        <Badge bg="danger">
                            <FontAwesomeIcon icon={faXmark} size="lg" />
                        </Badge>}{' '}
                        {(props.user.firstname + " " + props.user.lastname).substring(0, 15)}{(props.user.firstname + " " + props.user.lastname).length > 14 && ".."} {' '}
                        
                    </div>

                    {props.user.email}
                </div>
            </Col>
            <Col xs="4">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('wallet.balance')}
                    </div>

                    € {parseWithDecimals(props.user.availableBalance, 2)}
                </div>
            </Col>
            <Col xs="1" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.user)}>{t('page.details')}</NavDropdown.Item>

                    {props.user.active &&
                        <NavDropdown.Item onClick={() => props.actions.roles(props.user)}>{t('page.roles')}</NavDropdown.Item>
                    }

                    {props.user.active &&
                        <NavDropdown.Item onClick={() => props.actions.deposit(props.user)}>{t('page.deposit')}</NavDropdown.Item>
                    }

                    <NavDropdown.Item onClick={() => props.actions.withdraw(props.user)}>{t('page.withdraw')}</NavDropdown.Item>

                    {props.user.active &&
                        <NavDropdown.Item onClick={() => props.actions.kick(props.user)}>{t('action.kick')}</NavDropdown.Item>
                    }
                </NavDropdown>
                </Button>
            </Col>
            </Row>
    </ListGroup.Item>);
}

export default UserListItem;