import Modal from 'react-bootstrap/Modal';
import { requestResetPassword } from '../../services/AuthActions'
import { Button, Form} from "react-bootstrap";
import { useState } from 'react';
import { success } from "../../components/Toasts"
import { useTranslation } from 'react-i18next';

function RequestResetPasswordModal(props) {
    const { t } = useTranslation();

    const [emailValue, setEmailValue] = useState("");

    const [validated, setValidated] = useState(false);
  
    function resetModal() {
      setEmailValue("");
      setValidated(false);
    };
  
    function handleRequestResetPassword(e) {
      e.preventDefault();
      
      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }
  
      requestResetPassword(emailValue).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }
  
        success(t('success.PASSWORD_RESET_SENT'));
        resetModal();
        props.onHide();
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("page.request_password_reset")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleRequestResetPassword}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control type="email" name="email" placeholder={t("input.email")} onChange={(e) => setEmailValue(e.target.value)} value={emailValue} required/>
              <Form.Control.Feedback type="invalid">translated invalid email</Form.Control.Feedback>
            </Form.Group>
            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.send")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

export default RequestResetPasswordModal;