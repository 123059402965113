import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { formatTime, getTranslatedWeekday } from '../../helpers/DateTime'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

function TemplateListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col md="4" xs="5">
                <div className="fw-bold">
                    <span style={{lineBreak: "anywhere"}}>{props.template.productListName}{' '}</span>
                </div>
            </Col>
            <Col md="4" xs="5">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('day_of_week')}
                    </div>

                    {getTranslatedWeekday(t, props.template.dayOfWeek)}
                </div>
            </Col>
            <Col md="2" xs="2" className="d-none d-md-block">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('input.time_limit')}
                    </div>

                    {formatTime(props.template.limit)}
                </div>
            </Col>
            <Col md="2" xs="2" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.template)}>{t('page.details')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => props.actions.disable(props.template)}>{t('action.delete')}</NavDropdown.Item>
                </NavDropdown>
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default TemplateListItem;