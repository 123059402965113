import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { parseWithDecimals } from '../../../helpers/Number';
import { formatDateTime } from '../../../helpers/DateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'react-bootstrap/Badge';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react';
import { getGroupOrderProducts } from '../OrdersActions';
import { useParams } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';

function DetailsOrderModal(props) {
    const { t } = useTranslation();

    const { groupId } = useParams();
  
    const [orderedProducts, setOrderedProducts] = useState([]);

    useEffect(() => {
      setOrderedProducts([])

      if (props.order) {
        getOrderedProducts(groupId, props.order.orderId);
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    function getOrderedProducts(groupId, orderId) {
      getGroupOrderProducts(groupId, orderId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.products && responseData.products.length < 1) {
          setOrderedProducts([]);

          return;
        }
      
        setOrderedProducts(responseData.products);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.order && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.order.firstname}{' '}{props.order.lastname}</h1>
              <p>{props.order.email}</p>
              <p>{props.order.orderFulfilled &&
                <Badge bg="success" style={{fontSize: "1rem"}}>
                    <FontAwesomeIcon icon={faCheck} size="lg" /> {t('status.success')}
                </Badge>}

                {props.order.orderCancelled &&
                <Badge bg="danger" style={{fontSize: "1rem"}}>
                    <FontAwesomeIcon icon={faXmark} size="lg" /> {t('status.cancelled')}
                </Badge>}

                {(!props.order.orderCancelled && !props.order.orderFulfilled) && 
                <Badge bg="warning" style={{fontSize: "1rem"}}>
                    <FontAwesomeIcon icon={faSpinner} size="lg" /> {t('status.pending')}
                </Badge>}</p>
              <p><span style={{fontWeight: "bold"}}>{t('created')}</span><br/>{formatDateTime(props.order.orderCreated)}</p>
              <p><span style={{fontWeight: "bold"}}>{t('cost')}</span><br/>€ {parseWithDecimals(props.order.orderCost, 2)}</p>
              <p><span style={{fontWeight: "bold"}}>{t('product_list')}</span><br/>{props.order.productListName}</p>
              <p style={{marginBottom: "0"}}><span style={{fontWeight: "bold"}}>{t('ordered_products')}</span></p>
              <div style={{width: "20rem", margin: "auto"}}>
                <ListGroup variant="flush">
                {orderedProducts && orderedProducts.map((orderedProduct) => {
                    return <ListGroup.Item><b>{orderedProduct.quantity}x</b> {orderedProduct.name} €{parseWithDecimals(orderedProduct.price, 2)} {orderedProduct.quantity > 1 ? "- (€" + parseWithDecimals(orderedProduct.quantity * orderedProduct.price, 2) + ")" : null}</ListGroup.Item>
                })}
                </ListGroup>
              </div>
          </Col>
        </Row>
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsOrderModal;