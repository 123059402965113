import { logout } from "../../services/AuthActions";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { success } from "../../components/Toasts"

const LogoutPage = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    handleLogout();
  }, [navigate]);

  function handleLogout() {
    logout().then(response => {
      if(response.status !== 200) {
        return;
      }

      success("You have been logged out")
      localStorage.removeItem("token");
      navigate("/login");
    }).catch(error => {
      if (!error.response) {
        return;
      }

      localStorage.removeItem("token");
    });
  }

  return ("");
}

export default LogoutPage;