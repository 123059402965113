import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { formatTime, getTranslatedWeekday } from '../../../helpers/DateTime'

function DetailsTemplateModal(props) {
    const { t } = useTranslation();

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.template && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.template.productListName}</h1>
              <p>{props.template.productListDescription}</p>

              <p><span style={{fontWeight: "bold"}}>{t('day_of_week')}</span><br/>{getTranslatedWeekday(t, props.template.dayOfWeek)}</p>
              <p><span style={{fontWeight: "bold"}}>{t('input.time_limit')}</span><br/>{formatTime(props.template.limit)}</p>
          </Col>
        </Row>
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsTemplateModal;