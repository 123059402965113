import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupProductLists(id, page, search) {
    let queryParameters = "?search=" + search + "&page=" + page

    return axios.get(API_URL + "groups/" + id + "/lists" + queryParameters, {
        headers: authHeader()
    });
}

export function getGroupProductListById(groupId, listId) {
  return axios.get(API_URL + "groups/" + groupId + "/lists/" + listId, {
      headers: authHeader()
  });
}

export function getProductsById(groupId, listId, page, search) {
  let queryParameters = "?search=" + search + "&page=" + page

  return axios.get(API_URL + "groups/" + groupId + "/lists/" + listId + "/products" + queryParameters, {
      headers: authHeader()
  });
}

export function createGroupProductList(groupId, name, description) {
    return axios({
      method: 'post',
      url: API_URL + "groups/" + groupId + "/lists",
      headers: authHeader(),
      data: {
        name, 
        description
      }
    })
}

export function disableGroupProductList(groupId, listId) {
  return axios({
    method: 'delete',
    url: API_URL + "groups/" + groupId + "/lists/" + listId,
    headers: authHeader()
  })
}