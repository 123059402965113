import Modal from 'react-bootstrap/Modal';
import { getTokenGroup, joinGroup } from '../../pages/Groups/GroupsActions'
import { Button, Form} from "react-bootstrap";
import { useState } from 'react';
import { containsError } from '../../services/ErrorResolver';
import { success, error } from "../../components/Toasts"
import { useTranslation } from 'react-i18next';

function JoinGroupModal(props) {
    const { t } = useTranslation();

    const [joinCode, setJoinCode] = useState("");
    const [validated, setValidated] = useState(false);
  
    const [group, setGroup] = useState();
  
    const onJoinChange = (e) => {
      setGroup();
  
      setJoinCode(e.target.value);
    };
  
    function resetModal() {
      setJoinCode("");
      setGroup();
      setValidated(false);
    };
  
    function handleGetTokenGroup(e) {
      e.preventDefault();
  
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
  
      setValidated(true);
  
      getTokenGroup(joinCode).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "INVALID_TOKEN")) {
            error(t("error.INVALID_TOKEN"));
          }

          if (containsError(errors, "EXPIRED_TOKEN")) {
            error(t("error.EXPIRED_TOKEN"));
          }

          return;
        }
  
        let responseData = response.data.data;
        if (responseData.groups && responseData.groups.length < 1) {
          // NO JOINED GROUPS ERROR
          return;
        }
  
        setGroup(responseData.group)
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    function handleJoinGroup(e) {
      e.preventDefault();
  
      joinGroup(joinCode).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "ALREADY_JOINED_GROUP")) {
            error(t("error.ALREADY_JOINED_GROUP"));
          }
          return;
        }
  
        success(t("success.JOINED_GROUP"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t("page.join_group")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {group && 
            <>
              <h1>{group.name}</h1>
              <p>{group.description}</p>
              <Form noValidate onSubmit={handleJoinGroup}>
                <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit">{t("action.join_group")}</Button>
                    <Button variant="secondary" size="lg" onClick={() => resetModal()}>{t("action.cancel")}</Button>
                </div>
              </Form>
            </>
          }
  
        {!group && 
          <Form noValidate validated={validated} onSubmit={handleGetTokenGroup}>
            <Form.Group className="mb-3" controlId="formJoinCode">
              <Form.Control type="text" name="code" placeholder={t("input.join_code")} onChange={onJoinChange} value={joinCode} required/>
              <Form.Control.Feedback type="invalid">translated invalid code</Form.Control.Feedback>
            </Form.Group>
            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.search")}
              </Button>
            </div>
          </Form>
        }
        </Modal.Body>
      </Modal>);
  }

export default JoinGroupModal;