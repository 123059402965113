import Modal from 'react-bootstrap/Modal';
import { Button, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import InputGroup from 'react-bootstrap/InputGroup';
import { success, error } from "../../../components/Toasts"
import { useState } from 'react';
import { withdraw } from '../UsersActions'
import { useParams } from "react-router-dom";
import { containsError } from '../../../services/ErrorResolver';

function WithdrawUserModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();
  
    const [validated, setValidated] = useState(false);

    const [withdrawValue, setWithdrawValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState("");

    function resetState() {
      setValidated(false);
      setWithdrawValue("");
      setDescriptionValue("");
    }

    const onWithdrawChange = (e) => {
      const allowedChars = '0123456789.,';

      if (e.nativeEvent.data === null || allowedChars.includes(e.nativeEvent.data)) {
        setWithdrawValue(e.target.value.replace(',', '.'));
      }
    };

    function handleUserWithdraw(e) {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
  
      if (withdrawValue === 0) {
        return;
      }

      setValidated(true);

      withdraw(groupId, props.user.wallet_id, withdrawValue, descriptionValue).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "INSUFFICIENT_FUNDS")) {
            error(t("error.INSUFFICIENT_FUNDS"));
          }
          setValidated(false);
          return;
        }
  
        success(t("success.WITHDRAWN"))
        
        resetState();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.withdraw')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.user && 
        <>
          <Row className="justify-content-center text-center">
            <Col>
                <h1>{props.user.firstname}{' '}{props.user.lastname}</h1>
                <p>{props.user.email}</p>
            </Col>
          </Row>

          <Row className="justify-content-center text-center">
            <Col md={2} xs={4}>
                <p><span style={{fontWeight: "bold"}}>{t('wallet.balance')}</span><br/>€ {(Math.round(props.user.availableBalance * 100) / 100).toFixed(2)}</p>
            </Col>
            {withdrawValue.length > 0 &&
              <Col md={2} xs={4}>
                <p><span style={{fontWeight: "bold", color: "red"}}>{t('wallet.new_balance')}</span><br/>€ {(Math.round((parseFloat(props.user.availableBalance) - parseFloat(withdrawValue)) * 100) / 100).toFixed(2)}</p>
              </Col>
            }
          </Row>
        </>
        }

        <Form className="mb-3 mt-3" noValidate validated={validated} onSubmit={handleUserWithdraw}>
          <Row className="justify-content-center text-center">
            <Col md={3} xs={7}>
              <Form.Group className="mb-3" controlId="formWithdraw">
                <InputGroup>
                  <InputGroup.Text id="withdraw-addon">€</InputGroup.Text>
                  <Form.Control placeholder={t('action.withdraw')} aria-describedby="withdraw-addon" onChange={onWithdrawChange} value={withdrawValue} required/>
                  <Form.Control.Feedback type="invalid">translated invalid withdraw message</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center text-center pt-3">
            <Col md={6} xs={10}>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Control type="text" placeholder={t("input.description")} onChange={(e) => setDescriptionValue(e.target.value)} value={descriptionValue} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center text-center pt-3">
            <Col>
              <Button variant="primary" type="submit">
                {t('action.withdraw')}
              </Button>
            </Col>
          </Row>
        </Form>
        </Modal.Body>
      </Modal>);
  }

  export default WithdrawUserModal;