import { Col, Row, Container, Card } from "react-bootstrap";

import { useParams } from "react-router-dom";

import { useState, useEffect } from 'react';

import { getUpcomingSchedules } from './UpcomingActions'

import ListGroup from 'react-bootstrap/ListGroup';

import { getGroupById } from '../Groups/GroupsActions'

import { useTranslation } from 'react-i18next';

import UpcomingScheduleListItem from "../../components/List/UpcomingScheduleListItem";

function UpcomingPage() {
    const { groupId } = useParams();
    const { t } = useTranslation();

    const [group, setGroup] = useState();
    const [schedules, setSchedules] = useState([]);
    
    useEffect(() => {
      getGroup(groupId);
      getUpcoming(groupId);
    }, [groupId]);

    function getGroup(id) {
      getGroupById(id).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
  
        setGroup(response.data.data.group);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getUpcoming(id) {
      getUpcomingSchedules(id).then(response => {
          if(response.status !== 200) {
            return;
          }
    
          if (response.data.errors) {
            // NOP
            return;
          }

          let responseData = response.data.data;
          if (responseData.schedules && responseData.schedules.length < 1) {
            // NO SCHEDULES ERROR
            return;
          }

          const schedules = responseData.schedules;

          
          schedules.sort(function(a,b){
            return new Date(b.date + " " + b.limit) - new Date(a.date + " " + a.limit);
          });

          schedules.reverse()

          setSchedules(schedules);
        }).catch(error => {
          if (!error.response) {
            return;
          }

          // NOP
        });
      }

    return (
        <div>
            <Container>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{group && group.name}</h1>
                                
                                {schedules.length > 0 && 
                                    <ListGroup as="ul" key="schedules" style={{maxHeight: "20rem", overflow: "auto"}}>
                                        {schedules && schedules.map((schedule) => {
                                            return <UpcomingScheduleListItem group={groupId} schedule={schedule} key={schedule.id}/>
                                        })}
                                    </ListGroup>
                                }
                                
                                {(schedules.length < 1 || !schedules) && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t("no_upcoming_schedules")}</p>
                                    </div>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default UpcomingPage;