import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupSchedule, getGroupScheduleProducts, getGroupUser } from './OrderActions'
import { getUserWallet } from '../Wallet/WalletActions'
import { getUserWalletById } from '../Wallet/WalletActions'

import OrderProductListItem from "./OrderProductListItem";

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

import Badge from 'react-bootstrap/Badge';
import ShoppingCartModal from "./modals/ShoppingCartModal";

function OrderPage() {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");

    const { groupId } = useParams();
    const { scheduleId } = useParams();

    const { userId } = useParams();
    const [user, setUser] = useState();

    const [schedule, setSchedule] = useState();
    const [products, setProducts] = useState([]);
    const [wallet, setWallet] = useState();

    const [filterProducts, setFilterProducts] = useState([]);

    const [orderProducts, setOrderProducts] = useState([]);

    const [shoppingCartModalShow, setShoppingCartModalShow] = useState(false);

    let userActions = {
      addProduct: (product) => {
        if (!orderProducts.filter(orderProduct => orderProduct.id === product.id).length > 0) {
          let orderProduct = {quantity: 1, ...product}

          setOrderProducts([...orderProducts, orderProduct]);
        } else {
          let updatedOrderProducts = [...orderProducts];
          let toUpdateOrderProduct = updatedOrderProducts.find(orderProduct => orderProduct.id === product.id);

          toUpdateOrderProduct.quantity++;
          setOrderProducts(updatedOrderProducts);
        }
      },
      removeProduct: (product) => {
        if (orderProducts.filter(orderProduct => orderProduct.id === product.id).length > 0) {
          let updatedOrderProducts = [...orderProducts];
          let toUpdateOrderProduct = updatedOrderProducts.find(orderProduct => orderProduct.id === product.id);

          toUpdateOrderProduct.quantity--;

          if (toUpdateOrderProduct.quantity < 1) {
            let index = updatedOrderProducts.findIndex(orderProduct => orderProduct.id === toUpdateOrderProduct.id)
            updatedOrderProducts.splice(index, 1)
            console.log(updatedOrderProducts)
          }

          setOrderProducts(updatedOrderProducts);
        }
      }
    }

    function onOrderCreated(created) {
      setShoppingCartModalShow(false);

      if (created) {
        setOrderProducts([]);
        return navigate("/groups/" + groupId);
      }
    }

    useEffect(() => {
      // Check if userId is set else get wallet of that user Id
      if (userId !== undefined) {
        getOtherWallet(groupId, userId);
        getUser(groupId, userId);
      } else {
        getWallet(groupId);
      }

      getSchedule(groupId, scheduleId);
      getScheduleProducts(groupId, scheduleId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getSchedule(groupId, scheduleId) {
      getGroupSchedule(groupId, scheduleId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.schedule && responseData.schedule.length < 1) {
          setSchedule();

          return;
        }
      
        setSchedule(responseData.schedule[0]);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getWallet(groupId) {
      getUserWallet(groupId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
        
        setWallet(response.data.data.wallet);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getOtherWallet(groupId, userId) {
      getUserWalletById(groupId, userId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
        
        setWallet(response.data.data.wallet);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getUser(groupId, userId) {
      getGroupUser(groupId, userId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
        
        setUser(response.data.data.user);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getScheduleProducts(groupId, scheduleId) {
      getGroupScheduleProducts(groupId, scheduleId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.products && responseData.products.length < 1) {
          setProducts([]);
          setFilterProducts([])

          return;
        }
      
        setProducts(responseData.products);
        setFilterProducts(responseData.products)
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
  
      if (searchValue.length > 1) {
        setFilterProducts(products.filter(product => product.name.toLowerCase().includes(searchValue.toLowerCase())));
      }
    }

    function handleClear(e) {
      e.preventDefault();
  
      setSearchValue("");
      setFilterProducts(products)
    }

    function countProducts() {
      let productsCount = 0;

      orderProducts.forEach(orderProduct => {
        productsCount += orderProduct.quantity;
      });

      return productsCount;
    }

    return (
        <div>
            <ShoppingCartModal show={shoppingCartModalShow} onHide={onOrderCreated} products={orderProducts} wallet={wallet} actions={userActions} user={user} />

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <Row>
                                  <h4>{user && user.firstname + ' ' + user.lastname}</h4>
                                </Row>
                                <Row>
                                  <Col>
                                    <h1>{t("page.order")}{' '}{schedule && schedule.productListName}{' '} 
                                    <Button variant="primary" onClick={() => setShoppingCartModalShow(true)} disabled={orderProducts.length < 1}>
                                        <FontAwesomeIcon icon={faCartShopping} size="lg" /> {t('action.shopping_cart')} <Badge bg="secondary" >{countProducts()}</Badge>
                                    </Button></h1>
                                  </Col>
                                </Row>

                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {filterProducts.length > 0 && 
                                    <ListGroup as="ul" key="products">
                                        {filterProducts && filterProducts.map((product) => {
                                            return <OrderProductListItem group={groupId} product={product} key={product.id} actions={userActions}/>
                                        })}
                                    </ListGroup>
                                }

                                {filterProducts.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_products')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default OrderPage;