import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupOrders(id, page, search) {
    let queryParameters = "?search=" + search + "&page=" + page
    
    return axios.get(API_URL + "groups/" + id + "/orders" + queryParameters, {
        headers: authHeader()
    });
}

export function getGroupOrderProducts(groupId, orderId) {
    return axios.get(API_URL + "groups/" + groupId + "/orders/" + orderId + "/products", {
        headers: authHeader()
    });
}

export function cancelGroupOrder(groupId, orderId) {
    return axios.delete(API_URL + "groups/" + groupId + "/orders/" + orderId, {
        headers: authHeader()
    });
}