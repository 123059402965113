import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";
import CustomPagination from '../../components/CustomPagination'

import { getGroupExpiredSchedules, getGroupSchedules } from './SchedulesActions'

import { useTranslation } from 'react-i18next';
import ScheduleListItem from "./ScheduleListItem";
import ExpiredScheduleListItem from "./ExpiredScheduleListItem";

import CreateScheduleModal from "./modals/CreateScheduleModal";
import CancelScheduleModal from "./modals/CancelScheduleModal";
import DetailsScheduleModal from "./modals/DetailsScheduleModal";
import FulfillScheduleModal from "./modals/FulfillScheduleModal";
import OrderScheduleModal from "./modals/OrderScheduleModal";
import TemplateModule from "../../modules/Templates/TemplateModule";

function SchedulesPage() {
    const { t } = useTranslation();

    const { groupId } = useParams();

    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);

    const [expiredSchedules, setExpiredSchedules] = useState([]);

    const [schedules, setSchedules] = useState([]);
    const [totalSchedules, setTotalSchedules] = useState();

    const [modalSchedule, setModalSchedule] = useState();

    const [orderModalShow, setOrderModalShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [fulfillModalShow, setFulfillModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);

    let userActions = {
      create: () => {
        setCreateModalShow(true)
      },
      details: (schedule) => {
        setModalSchedule(schedule)
        setDetailsModalShow(true)
      },
      fulfill: (schedule) => {
        setModalSchedule(schedule)
        setFulfillModalShow(true)
      },
      cancel: (schedule) => {
        setModalSchedule(schedule)
        setCancelModalShow(true)
      },
      order: (schedule) => {
        setModalSchedule(schedule)
        setOrderModalShow(true)
      }
    }

    function refreshList() {
      getExpiredSchedules(groupId)
      getSchedules(groupId, page, searchValue);
    }

    function onScheduleCreate(created) {
      if (created) {
        refreshList();
      }

      setCreateModalShow(false);
    }

    function onScheduleDetails() {
      setDetailsModalShow(false);
    }

    function onScheduleOrder() {
      setOrderModalShow(false);
    }

    function onScheduleFulfill(created) {
      if (created) {
        refreshList();
      }

      setFulfillModalShow(false);
    }

    function onScheduleCancel(created) {
      if (created) {
        refreshList();
      }

      setCancelModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getSchedules(groupId, page, searchValue);
    }

    useEffect(() => {
      getExpiredSchedules(groupId)

      getSchedules(groupId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getExpiredSchedules(groupId) {
      getGroupExpiredSchedules(groupId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.schedules && responseData.schedules.length < 1) {
          setExpiredSchedules([])

          return;
        }
      
        setExpiredSchedules(responseData.schedules);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getSchedules(groupId, page, searchValue) {
      getGroupSchedules(groupId, page, searchValue).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.schedules && responseData.schedules.length < 1) {
          setSchedules([])
          setTotalSchedules()

          return;
        }
      
        setSchedules(responseData.schedules);
        setTotalSchedules(responseData.total)
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getSchedules(groupId, page, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");

      getExpiredSchedules(groupId);
      getSchedules(groupId, 1, "");
    }

    return (
        <div>
            <OrderScheduleModal show={orderModalShow} onHide={onScheduleOrder} schedule={modalSchedule} />
            <CreateScheduleModal show={createModalShow} onHide={onScheduleCreate} />
            <CancelScheduleModal show={cancelModalShow} onHide={onScheduleCancel} schedule={modalSchedule} />
            <FulfillScheduleModal show={fulfillModalShow} onHide={onScheduleFulfill} schedule={modalSchedule} />
            <DetailsScheduleModal show={detailsModalShow} onHide={onScheduleDetails} schedule={modalSchedule} />

            <Container fluid>
            <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t('page.expired_schedules')}</h1>

                                {expiredSchedules.length > 0 && 
                                    <ListGroup as="ul" key="expiredSchedulesList">
                                        {expiredSchedules && expiredSchedules.map((schedule) => {
                                            return <ExpiredScheduleListItem schedule={schedule} key={schedule.id} actions={userActions} />
                                        })}
                                    </ListGroup>
                                }

                                {expiredSchedules.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_schedules')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <TemplateModule></TemplateModule>

                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t('page.schedules')}
                                  <Button variant="primary" type="submit" style={{marginLeft: "0.6rem"}} onClick={() => setCreateModalShow(true)}>
                                    {t('action.create')}
                                  </Button>
                                </h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {schedules.length > 0 && 
                                    <ListGroup as="ul" key="schedulesList">
                                        {schedules && schedules.map((schedule) => {
                                            return <ScheduleListItem schedule={schedule} key={schedule.id} actions={userActions} />
                                        })}
                                    </ListGroup>
                                }

                                {totalSchedules !== undefined && schedules.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalSchedules} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {schedules.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_schedules')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SchedulesPage;