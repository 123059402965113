import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouteGuard from "./components/RouteGuard"

import { Routes, Route, Navigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WalletPage from "./pages/Wallet";
import GroupsPage from "./pages/Groups";
import Login from "./pages/Login";
import Register from './pages/Register';
import EmailConfirmation from './pages/EmailConfirmation';

import "./i18n"
import LogoutPage from './pages/Logout/Logout';
import UpcomingPage from './pages/Upcoming/UpcomingPage';
import ProductListsPage from './pages/ProductLists/ProductListsPage';
import PageLayout from './pages/PageLayout'
import UsersPage from './pages/Users/UsersPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import TokensPage from './pages/Tokens/TokensPage';
import ProductsPage from './pages/Products/ProductsPage';
import SchedulesPage from './pages/Schedules/SchedulesPage';
import OrdersPage from './pages/Orders/OrdersPage';
import MyOrdersPage from './pages/MyOrders/MyOrdersPage';
import OrderPage from './pages/Order/OrderPage';
import UserSettingsPage from './pages/UserSettings/UserSettingsPage'
import GroupSettingsPage from './pages/GroupSettings/GroupSettingsPage';

function App() {
  return (
    <>
      <Routes>
        <Route element={<PageLayout />}>
          <Route exact path="/settings" element={
            <RouteGuard>
              <UserSettingsPage />
            </RouteGuard>
          } />

          <Route exact path="/groups" element={
            <RouteGuard>
              <GroupsPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId" element={
            <RouteGuard>
              <UpcomingPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/settings" element={
            <RouteGuard>
              <GroupSettingsPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/users" element={
            <RouteGuard>
              <UsersPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/tokens" element={
            <RouteGuard>
              <TokensPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/product-lists" element={
            <RouteGuard>
              <ProductListsPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/product-lists/:listId/products" element={
            <RouteGuard>
              <ProductsPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/schedules" element={
            <RouteGuard>
              <SchedulesPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/schedule/:scheduleId/order" element={
            <RouteGuard>
              <OrderPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/schedule/:scheduleId/order/user/:userId" element={
            <RouteGuard>
              <OrderPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/orders" element={
            <RouteGuard>
              <OrdersPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/my-orders" element={
            <RouteGuard>
              <MyOrdersPage />
            </RouteGuard>
          } />

          <Route path="/groups/:groupId/wallet" element={
            <RouteGuard>
              <WalletPage />
            </RouteGuard>
          } />

          <Route exact path="/logout" element={
            <RouteGuard>
              <LogoutPage />
            </RouteGuard>
          } />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign-up" element={<Register />} />
          <Route exact path="/email-confirmation" element={<EmailConfirmation />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />

          {/* TODO create unprotected home page */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>

      <ToastContainer />
    </>
  )
}

export default App
