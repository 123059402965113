import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function DetailsUserModal(props) {
    const { t } = useTranslation();
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.user && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.user.firstname}{' '}{props.user.lastname}</h1>
              <p>{props.user.email}</p>
              <p><span style={{fontWeight: "bold"}}>{t('wallet.balance')}</span><br/>€ {(Math.round(props.user.availableBalance * 100) / 100).toFixed(2)}</p>
          </Col>
        </Row>
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsUserModal;