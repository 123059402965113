import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { parseWithDecimals } from '../../../helpers/Number';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import { orderGroupScheduleProducts } from '../OrderActions';
import { useParams } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import { success, error } from '../../../components/Toasts'
import ShoppingCartListItem from '../ShoppingCartListItem';
import { containsError } from '../../../services/ErrorResolver';

function ShoppingCartModal(props) {
    const { t } = useTranslation();

    const { groupId } = useParams();
    const { scheduleId } = useParams();

    const [totalPrice, setTotalPrice] = useState();

    const [processingRequest, setProcessingRequest] = useState(false);

    useEffect(() => {
      let total = props.products.map((orderProduct) => {
        return orderProduct.quantity * orderProduct.price;
      })

      if (props.products.length > 0) {
        setTotalPrice(total.reduce((a, b) => a + b));
      } else {
        setTotalPrice(0);
      }
    }, [props.products]);

    function orderProducts() {
      let parsedProducts = props.products.map((orderProduct) => {
        return {quantity: orderProduct.quantity, id: orderProduct.id};
      })

      setProcessingRequest(true)
      
      orderGroupScheduleProducts(groupId, scheduleId, parsedProducts, props.user?.id).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "INSUFFICIENT_FUNDS")) {
            error(t("error.INSUFFICIENT_FUNDS"));
          }

          if (containsError(errors, "SCHEDULE_EXPIRED")) {
            error(t("error.SCHEDULE_EXPIRED"));
          }
          return;
        }

        let responseData = response.data.data;
        if (responseData.order && responseData.order.length < 1) {
          // NOP
          return;
        }
      
        success(t("success.CREATED_ORDER"))
  
        // show order modal
        props.onHide(true);
      }).catch(err => {
        if (!err.response) {
          return;
        }

        let errors = err.response.data.errors;
        if (containsError(errors, "MISSING_PRODUCTS")) {
          error(t("error.MISSING_PRODUCTS"));
        }

        return;
      }).finally(() => {
        setProcessingRequest(false)
      });
    }

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.shopping_cart')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.products && 
        <>
          <Row>
            <Col>
                <ListGroup variant="flush" key="products">
                    {props.products && props.products.map((orderProduct) => {
                        return <ShoppingCartListItem group={groupId} product={orderProduct} key={orderProduct.id} actions={props.actions} />
                    })}
                </ListGroup>

                {props.products && props.products.length < 1 &&
                  <div className="justify-content-center align-items-center d-flex">
                    <p style={{fontWeight: "bold"}}>{t('shopping_cart_empty')}</p>
                </div>
                }
            </Col>
          </Row>
          <hr style={{margin: "0", marginBottom: "0.5rem", borderColor: "#817a7a"}} />
          <Row>
              <Col xs={8}>
                <p>{t('total')}</p>
              </Col>
              <Col xs={4} style={{textAlign: "right"}}>
                <p><b>€ {parseWithDecimals((totalPrice), 2)}</b></p>
              </Col>
          </Row>
          <hr style={{margin: "0", marginBottom: "0.5rem"}} />
          <Row>
              <Col xs={8}>
                <p>{t('wallet.current_balance')}</p>
              </Col>
              <Col xs={4} style={{textAlign: "right"}}>
                <p><b>€ {props.wallet && parseWithDecimals(props.wallet.availableBalance, 2)}</b></p>
              </Col>
          </Row>
          <Row>
              <Col xs={8}>
                <p>{t('wallet.balance_after_order')}</p>
              </Col>
              <Col xs={4} style={{textAlign: "right"}}>
                <p className={(props.wallet && parseWithDecimals((props.wallet.availableBalance - totalPrice), 2) < 0) ? "text-danger" : ""}><b>€ {props.wallet && parseWithDecimals((props.wallet.availableBalance - totalPrice), 2)}</b></p>
              </Col>
          </Row>
          <Row>
            <Col className="pb-3" style={{textAlign: "right"}}>
              <Button variant="primary" onClick={() => orderProducts()} disabled={props.products.length < 1 || processingRequest}>
                {t('action.order')}
              </Button>
            </Col>
          </Row>
        </>
        }
        </Modal.Body>
      </Modal>);
  }

  export default ShoppingCartModal;