import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faXmark } from '@fortawesome/free-solid-svg-icons'


function DetailsProductListModal(props) {
    const { t } = useTranslation();
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.productlist && 
        <>
          <Row className="justify-content-center text-center">
            <Col>
                <h1>{props.productlist.name}</h1>
                <p>{!props.productlist.active &&
                    <Badge bg="danger" style={{fontSize: "1rem"}}>
                        <FontAwesomeIcon icon={faXmark} size="lg" /> {t('action.deleted')}
                    </Badge>}
                  {' '}
                </p>
                <br />
                
                <p>{props.productlist.description}</p>
            </Col>
          </Row>
        </>
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsProductListModal;