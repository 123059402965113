import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';

import { formatDateTime } from '../../helpers/DateTime'

import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'

function ScheduleListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="4">
                <div className="fw-bold">
                    {props.schedule.fulfilled &&
                    <Badge bg="success">
                        <FontAwesomeIcon icon={faCheck} size="lg" /> 
                    </Badge>}

                    {(props.schedule.cancelled || props.schedule.deleted) &&
                    <Badge bg="danger">
                        <FontAwesomeIcon icon={faXmark} size="lg" /> 
                    </Badge>}

                    {!props.schedule.fulfilled && !props.schedule.cancelled && !props.schedule.deleted && moment(props.schedule.dateTimeLimit).isAfter(moment()) && 
                    <Badge bg="warning">
                        <FontAwesomeIcon icon={faSpinner} size="lg" /> 
                    </Badge>}

                    {!props.schedule.fulfilled && !props.schedule.cancelled && !props.schedule.deleted && moment(props.schedule.dateTimeLimit).isBefore(moment()) &&
                    <Badge bg="danger">
                        <FontAwesomeIcon icon={faStopwatch} size="lg" /> 
                    </Badge>}
                    {' '}
                    <span style={{lineBreak: "anywhere"}}>{props.schedule.productListName}{' '}</span>
                </div>
            </Col>
            <Col xs="5">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('expires_at')}
                    </div>

                    {formatDateTime(props.schedule.dateTimeLimit)}
                </div>
            </Col>
            <Col xs="3" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.schedule)}>{t('page.details')}</NavDropdown.Item>

                    {!props.schedule.cancelled && 
                        <>
                            <NavDropdown.Item onClick={() => props.actions.order(props.schedule)}>{t('page.order')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => props.actions.cancel(props.schedule)}>{t('action.cancel')}</NavDropdown.Item>
                        </>
                    }
                </NavDropdown>
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default ScheduleListItem;