import Pagination from 'react-bootstrap/Pagination';

import { useState, useEffect, useCallback } from 'react';

const CustomPagination = ({total, currentPage, callback}) => {
    const [maxPage, setMaxPage] = useState();
    const [pages, setPages] = useState([]);

    const buildPaginator = useCallback(() => { 
      let calculatedMaxPage = Math.ceil(total / 10);
      setMaxPage(calculatedMaxPage);
      
      const pagesToShow = [];

      if (currentPage < 3 && maxPage < 3) {
        pagesToShow.push(1);

        if (maxPage === 2) {
          pagesToShow.push(2);
        }
      }

      if (currentPage < 3 && maxPage > 2) {
        for (let i = 1; i < 4; i++) {
          pagesToShow.push(i);
        }
      }

      if (currentPage > 2) {
        let pageNumber;
        if (currentPage < maxPage) {
          pageNumber = currentPage - 1;
        } else {
          pageNumber = currentPage - 2;
        }

        for (let i = 1; i < 4; i++) {
          pagesToShow.push(pageNumber);
          pageNumber = pageNumber + 1;
        }
      }

      setPages(pagesToShow);
    }, [currentPage, total, maxPage]);

    function clicked(clickedPage) {
      if (clickedPage !== currentPage) {
        callback(clickedPage)
      }
    }

    function previousPage() {
      if (currentPage > 1) {
        clicked(currentPage - 1)
      }
    }

    function nextPage() {
      if (currentPage < maxPage) {
        clicked(currentPage + 1)
      }
    }

    useEffect(() => {
      buildPaginator();
    }, [maxPage, buildPaginator]);

    return (
        <Pagination>
          <Pagination.Prev onClick={() => previousPage()} />

          {pages && pages.map((p) => {
              return <Pagination.Item key={p} className={p === currentPage && "active"} onClick={()=>clicked(p)}>{p}</Pagination.Item>
          })}

          <Pagination.Next onClick={() => nextPage()}/>
        </Pagination>
      );
}

export default CustomPagination;