import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupProductLists } from './ProductListsActions'

import CustomPagination from '../../components/CustomPagination'

import { useTranslation } from 'react-i18next';

import ProductListListItem from "../../components/List/ProductListListItem";
import CreateProductListModal from "./modals/CreateProductListModal";
import DetailsProductListModal from "./modals/DetailsProductListModal";
import DeleteProductListModal from "./modals/DeleteProductListModal";

function ProductListsPage() {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [searchValue, setSearchValue] = useState("");

    const [page, setPage] = useState(1);

    const [productLists, setProductLists] = useState([]);
    const [totalProductLists, setTotalProductLists] = useState();

    const [modalProductList, setModalProductList] = useState();

    const [createModalShow, setCreateModalShow] = useState(false);
    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    let userActions = {
      details: (productList) => {
        setModalProductList(productList)
        setDetailsModalShow(true)
      },
      create: () => {
        setCreateModalShow(true)
      },
      delete: (productList) => {
        setModalProductList(productList)
        setDeleteModalShow(true)
      },
    }

    function refreshList() {
      getProductLists(groupId, page, searchValue);
    }

    function onProductListCreate(created, productList) {
      if (created) {
        refreshList();
      }

      if (productList) {
        setModalProductList(productList)
        setDetailsModalShow(true)
      }

      setCreateModalShow(false);
    }

    function onProductListDelete(disabled) {
      if (disabled) {
        refreshList();
      }

      setDeleteModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getProductLists(groupId, page, searchValue);
    }

    useEffect(() => {
      getProductLists(groupId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getProductLists(id, page, search) {
      getGroupProductLists(id, page, search).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.productLists && responseData.productLists.length < 1) {
          setTotalProductLists(0);
          setProductLists([]);

          return;
        }
      
        setTotalProductLists(responseData.total);
        setProductLists(responseData.productLists);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getProductLists(groupId, 1, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");
      getProductLists(groupId, 1, "");
    }

    return (
        <div>
            <CreateProductListModal show={createModalShow} onHide={onProductListCreate} />
            <DeleteProductListModal show={deleteModalShow} onHide={onProductListDelete} productlist={modalProductList} />
            <DetailsProductListModal show={detailsModalShow} onHide={() => setDetailsModalShow(false)} productlist={modalProductList} />

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t('page.product_lists')}
                                  <Button variant="primary" type="submit" style={{marginLeft: "0.6rem"}} onClick={() => setCreateModalShow(true)}>
                                    {t('action.create')}
                                  </Button>
                                </h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {productLists.length > 0 && 
                                    <ListGroup as="ul" key="productLists">
                                        {productLists && productLists.map((productList) => {
                                            return <ProductListListItem group={groupId} productlist={productList} key={productList.id} actions={userActions} />
                                        })}
                                    </ListGroup>
                                }

                                {totalProductLists !== undefined && productLists.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalProductLists} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {productLists.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_product_lists')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductListsPage;