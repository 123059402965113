import { Col, Row, Container, Card, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { getUserDetails, update } from './UserSettingsActions'
import Form from 'react-bootstrap/Form'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { error, success } from "../../components/Toasts"
import { containsError } from '../../services/ErrorResolver';
import { requestResetPassword } from '../../services/AuthActions';

function UserSettingsPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [userFormValue, setUserFormValue] = useState({
    email: '',
    firstName: '',
    language: 'en',
    lastName: '',
  });

  // Array of available languages, in the future this should be fetched from the backend
  const [availableLanguages, setAvailableLanguages] = useState([ // eslint-disable-line
    { value: "en", label: "English" },
    { value: "nl", label: "Nederlands" },
  ]);

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormChange = (e) => {
    setUserFormValue({ ...userFormValue, [e.target.name]: e.target.value });
  };

  function getDetails() {
    getUserDetails().then((response) => {
      let responseData = response.data.data;

      if (responseData.user) {
        setUserFormValue({
          firstName: responseData.user.firstName,
          lastName: responseData.user.lastName,
          email: responseData.user.email,
          language: responseData.user.language
        });
      }
    }).catch(_ => {
      // NOP
    });
  }

  function handleResetPassword() {
    requestResetPassword(userFormValue.email).then(response => {
      if (response.data.errors) {
        // NOP
        return;
      }

      success(t('success.PASSWORD_RESET_SENT'));
    }).catch(error => {
      if (!error.response) {
        return;
      }

      // NOP
    });
  }

  function handleBack() {
    navigate(-1);
  }

  function handleOnSave(e) {
    e.preventDefault();
    // Save user settings

    update(userFormValue.firstName, userFormValue.lastName, userFormValue.language).then((response) => {
      if (response.status !== 200) {
        return;
      }

      if (response.data.errors) {
        handleErrors(response.data.errors);
        return;
      }

      i18n.changeLanguage(userFormValue.language)

      success(t("success.USER_UPDATED"));
    }).catch(error => {
      if (error.response.data.errors) {
        handleErrors(error.response.data.errors);
      }
    });
  }

  function handleErrors(errors) {
    if (containsError(errors, "MISSING_FIRSTNAME")) {
      error(t("error.MISSING_FIRSTNAME"));
    }

    if (containsError(errors, "MISSING_LASTNAME")) {
      error(t("error.MISSING_LASTNAME"));
    }

    if (containsError(errors, "MISSING_LANGUAGE")) {
      error(t("error.MISSING_LANGUAGE"));
    }
  }

  return (
    <div>
      <Container fluid>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12} className="pb-3">
            <Card className="shadow">
              <Card.Body>
                <h1>{t("user")} {t("page.settings")}</h1>

                <Form className="mb-2" noValidate onSubmit={handleOnSave}>
                  <Form.Label>{t('input.first_name')}</Form.Label>
                  <Form.Control type="text" placeholder={t('input.first_name')} name="firstName" value={userFormValue.firstName} onChange={onFormChange} />

                  <Form.Label className="mt-4">{t('input.last_name')}</Form.Label>
                  <Form.Control type="text" placeholder={t('input.last_name')} name="lastName" value={userFormValue.lastName} onChange={onFormChange} />

                  <Form.Label className="mt-4">{t('input.email')}</Form.Label>
                  <Form.Control type="email" placeholder={t('input.email')} disabled name="email" value={userFormValue.email} onChange={onFormChange} />

                  <Form.Label className="mt-4">{t("input.select_language")}</Form.Label>
                  <Form.Select aria-label="Language" name="language" required value={userFormValue.language} onChange={onFormChange}>
                    {availableLanguages.length > 0 && availableLanguages.map((language) => {
                      return <option key={language.value} value={language.value}>{language.label}</option>
                    })}
                  </Form.Select>

                  <div className="mt-4 d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleResetPassword}>
                      {t('action.reset_password')}
                    </Button>
                    <div>
                      <Button variant="danger" onClick={handleBack} className="me-3">
                        {t('action.back')}
                      </Button>
                      <Button variant="primary" type="submit">
                        {t('action.save')}
                      </Button>
                    </div>

                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserSettingsPage;