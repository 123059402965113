
import { Link } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { login, getCurrentToken } from "../../services/AuthActions";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { containsError } from '../../services/ErrorResolver';

import { error } from "../../components/Toasts"

import logo from '../../images/lunchcash-logo.png';

import { useTranslation } from 'react-i18next';
import RequestResetPasswordModal from "../../components/modals/RequestResetPasswordModal";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);

  const [loginFormValue, setLoginFormValue] = useState({
    email: '',
    password: ''
  });

  const onLoginChange = (e) => {
    setLoginAttempted(false);

    setLoginFormValue({ ...loginFormValue, [e.target.name]: e.target.value });
  };
  
  useEffect(() => {
    if (getCurrentToken()) {
      navigate("/groups");
    }
  }, [navigate]);

  function showResetPasswordModal(e) {
    e.preventDefault();
    
    setResetPasswordModalShow(true)
  }

  function onResetPasswordHide() {
    setResetPasswordModalShow(false)
  }

  function handleLogin(e) {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    login(loginFormValue.email, loginFormValue.password).then(response => {
      if(response.status !== 200) {
        return;
      }

      let responseData = response.data.data;
      if (response.data.errors) {
        handleLoginErrors(response.data.errors);
        return;
      }

      i18n.changeLanguage(responseData.language)

      if (responseData.accessToken) {
        localStorage.setItem("token", responseData.accessToken);

        navigate("/groups");
      }
    }).catch(error => {
      if (!error.response) {
        return;
      }

      // Bad request (Frontend validation)

    });
  }

  function handleLoginErrors(errors) {
    if (containsError(errors, "INVALID_LOGIN")) {
      error(t("error.INVALID_LOGIN"));
      setLoginAttempted(true);
    }

    if (containsError(errors, "EMAIL_NOT_CONFIRMED")) {
      error(t("error.EMAIL_NOT_CONFIRMED"));
      setLoginAttempted(true);
    }
  }

  return (
    <div>
      <RequestResetPasswordModal show={resetPasswordModalShow} onHide={onResetPasswordHide} />

      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="mb-4 d-grid justify-content-center">
                    <img src={logo} style={{width: "10rem"}} alt=""/>
                  </div>
                  <div className="mb-3">
                    <Form noValidate validated={!loginAttempted && validated} onSubmit={handleLogin}>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label className="text-center">
                          {t("input.email")}
                        </Form.Label>
                        <Form.Control type="email" name="email" placeholder={t("input.email")} onChange={onLoginChange} value={loginFormValue.email} required/>
                        <Form.Control.Feedback type="invalid">translated invalid email message</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>
                        {t("input.password")}
                        </Form.Label>
                        <Form.Control type="password" name="password" placeholder={t("input.password")} onChange={onLoginChange} value={loginFormValue.password} required/>
                        <Form.Control.Feedback type="invalid">translated invalid password message</Form.Control.Feedback>
                      </Form.Group>
                      {loginAttempted && <p style={{color: "red"}}>{t("error.INVALID_LOGIN")}</p>}
                      <Form.Group className="mb-3" controlId="formCheckbox">
                        <p className="small">
                          <a className="text-primary" href="/" onClick={(e) => showResetPasswordModal(e)}>
                            {t("forgot_password")}
                          </a>
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          {t("action.login")}
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        {t("no_account")}{" "}
                        <Link to="/sign-up">{t("action.sign_up")}</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;