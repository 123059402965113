import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupExpiredSchedules(groupId) {
    return axios.get(API_URL + "groups/" + groupId + "/expired-schedules", {
        headers: authHeader()
    });
}

export function getGroupSchedules(groupId, page, search) {
    let queryParameters = "?search=" + search + "&page=" + page

    return axios.get(API_URL + "groups/" + groupId + "/schedules" + queryParameters, {
        headers: authHeader()
    });
}

export function getGroupScheduleOrderedProducts(groupId, scheduleId) {
  return axios.get(API_URL + "groups/" + groupId + "/schedules/" + scheduleId + "/ordered-products", {
      headers: authHeader()
  });
}

export function createGroupSchedule(groupId, listId, dateTime) {
    return axios({
      method: 'post',
      url: API_URL + "groups/" + groupId + "/schedules",
      headers: authHeader(),
      data: {
        listId,
        dateTime
      }
    })
}

export function fulfillGroupSchedule(groupId, listId) {
    return axios({
      method: 'put',
      url: API_URL + "groups/" + groupId + "/schedules/" + listId,
      headers: authHeader(),
      data: {
        "fulfilled": true
      }
    })
}

export function cancelGroupSchedule(groupId, listId) {
    return axios({
      method: 'put',
      url: API_URL + "groups/" + groupId + "/schedules/" + listId,
      headers: authHeader(),
      data: {
        "cancelled": true
      }
    })
}

export function getGroupAvailableProductLists(groupId) {
    return axios.get(API_URL + "groups/" + groupId + "/available-lists", {
        headers: authHeader()
    });
}

export function getGroupUsers(id) {
  return axios.get(API_URL + "groups/" + id + "/users/all", {
      headers: authHeader()
  });
}