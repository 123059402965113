import Modal from 'react-bootstrap/Modal';
import { disableGroupProductList } from '../ProductListsActions'
import { Button, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { success, error } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { containsError } from '../../../services/ErrorResolver';

function DeleteProductListModal(props) {
    const { t } = useTranslation();

    const { groupId } = useParams();
  
    function handleProductListToken(e) {
      e.preventDefault();

      disableGroupProductList(groupId, props.productlist.id).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;

          if (containsError(errors, "TEMPLATE_USING_PRODUCT_LIST")) {
            error(t("error.TEMPLATE_USING_PRODUCT_LIST"));
          }

          if (containsError(errors, "SCHEDULE_USING_PRODUCT_LIST")) {
            error(t("error.SCHEDULE_USING_PRODUCT_LIST"));
          }

          return;
        }
  
        success(t("success.PRODUCT_LIST_DISABLED"))
  
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('action.delete')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.productlist && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.productlist.name}</h1>
              <p>{props.productlist.description}</p>
          </Col>
        </Row>
        }

          <Form noValidate onSubmit={handleProductListToken}>
            <Row className="justify-content-center text-center mt-5">
              <Col>
                <Button variant="danger" type="submit" size="lg">
                  {t('action.delete')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default DeleteProductListModal;