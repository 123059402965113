import Modal from 'react-bootstrap/Modal';
import { updateProduct } from '../ProductActions'
import { Button, Form, Col, Row } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { success } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';

function UpdateProductModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();
    const { listId } = useParams();

    const [validated, setValidated] = useState(false);
  
    const [createFormValue, setCreateFormValue] = useState({
      price: ''
    });

    // const onUpdateChange = (e) => {
    //   setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    // };

    useEffect(() => {
      if (props.product) {
        setCreateFormValue({ ...createFormValue, "price": props.product.price});
      }

    }, [props.product]);

    const onPriceChange = (e) => {
      const allowedChars = '0123456789.,';

      if (e.nativeEvent.data === null || allowedChars.includes(e.nativeEvent.data)) {
        setCreateFormValue({ ...createFormValue, "price": e.target.value.replace(',', '.')});
      }
    };

    function resetModal() {
      setCreateFormValue({
        price: ''
      });

      setValidated(false);
    };
  
    function handleUpdateProduct(e) {
      e.preventDefault();
  
      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }
  
      updateProduct(groupId, listId, props.product.id, createFormValue.price).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
  
        success(t("success.UPDATED_PRODUCT"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("action.update")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.product && 
          <>
            <Row className="justify-content-center text-center">
              <Col>
                  <h1>{props.product.name}</h1>
                  <p>{props.product.description}</p>
              </Col>
            </Row>
            <Row>
              <Form noValidate validated={validated} onSubmit={handleUpdateProduct}>
              <Row className="justify-content-center text-center">
                <Col md={3} xs={7}>
                  <Form.Group className="mb-5" controlId="formPrice">
                    <InputGroup>
                      <InputGroup.Text id="price-addon">€</InputGroup.Text>
                      <Form.Control placeholder={t('input.price')} aria-describedby="price-addon" onChange={onPriceChange} value={createFormValue.price} required/>
                      <Form.Control.Feedback type="invalid">translated invalid withdraw message</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

                <div className="d-grid">
                  <Button variant="primary" type="submit">
                    {t("action.update")}
                  </Button>
                </div>
              </Form>
            </Row>
          </>
        }
        </Modal.Body>
      </Modal>);
  }

  export default UpdateProductModal;