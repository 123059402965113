import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupMyOrders } from './MyOrdersActions'

import CustomPagination from '../../components/CustomPagination'

import { useTranslation } from 'react-i18next';

import DetailsOrderModal from "./modals/DetailsOrderModal";
import OrderListItem from "./OrderListItem";
import CancelOrderModal from "./modals/CancelOrderModal";

function MyOrdersPage() {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [searchValue, setSearchValue] = useState("");

    const [page, setPage] = useState(1);

    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState();

    const [modalOrder, setModalOrder] = useState();

    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);

    let userActions = {
      details: (order) => {
        setModalOrder(order)
        setDetailsModalShow(true)
      },
      cancel: (order) => {
        setModalOrder(order)
        setCancelModalShow(true)
      }
    }

    function onScheduleCancel(cancel) {
      if (cancel) {
        refreshList();
      }

      setCancelModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getOrders(groupId, page, searchValue);
    }

    function refreshList() {
      getOrders(groupId, page, searchValue);
    }

    useEffect(() => {
      getOrders(groupId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getOrders(id, page, search) {
      getGroupMyOrders(id, page, search).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.orders && responseData.orders.length < 1) {
          setTotalOrders(0);
          setOrders([]);

          return;
        }
      
        setTotalOrders(responseData.total);
        setOrders(responseData.orders);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getOrders(groupId, 1, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");
      getOrders(groupId, 1, "");
    }

    return (
        <div>
          <DetailsOrderModal show={detailsModalShow} onHide={() => setDetailsModalShow(false)} order={modalOrder} />
          <CancelOrderModal show={cancelModalShow} onHide={onScheduleCancel} order={modalOrder}></CancelOrderModal>

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t("page.orders")}</h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {orders.length > 0 && 
                                    <ListGroup as="ul" key="orders">
                                        {orders && orders.map((order) => {
                                            return <OrderListItem group={groupId} order={order} key={order.orderId} actions={userActions}/>
                                        })}
                                    </ListGroup>
                                }

                                {totalOrders !== undefined && orders.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalOrders} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {orders.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_orders')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MyOrdersPage;