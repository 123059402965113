import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupDetails(groupId) {
  return axios.get(API_URL + "groups/" + groupId + "/settings", {
    headers: authHeader()
  });
}

export function update(groupId, name, description, allowDebt) {
  return axios({
    method: 'post',
    url: API_URL + "groups/" + groupId + "/settings",
    headers: authHeader(),
    data: {
      name,
      description,
      allowDebt
    }
  })
}