import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getUpcomingSchedules(id) {
    return axios.get(API_URL + "groups/" + id + "/upcoming-schedules", {
        headers: authHeader()
      });
}