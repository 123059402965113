import Modal from 'react-bootstrap/Modal';
import { deleteGroupTemplate } from '../TemplateActions'
import { Button, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { success } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';

import { formatTime, getTranslatedWeekday } from '../../../helpers/DateTime'

function DisableProductModal(props) {
    const { t } = useTranslation();

    const { groupId } = useParams();
  
    function handleTemplateDisable(e) {
      e.preventDefault();

      deleteGroupTemplate(groupId, props.template.id).then(response => {
        if (response.data.errors) {
          // NOP

          return;
        }
  
        success(t("success.TEMPLATE_DELETED"))
  
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('action.delete')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.template && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.template.productListName}</h1>
              <p>{props.template.productListDescription}</p>

              <p><span style={{fontWeight: "bold"}}>{t('day_of_week')}</span><br/>{getTranslatedWeekday(t, props.template.dayOfWeek)}</p>
              <p><span style={{fontWeight: "bold"}}>{t('input.time_limit')}</span><br/>{formatTime(props.template.limit)}</p>
          </Col>
        </Row>
        }

          <Form noValidate onSubmit={handleTemplateDisable}>
            <Row className="justify-content-center text-center mt-5">
              <Col>
                <Button variant="danger" type="submit" size="lg">
                  {t('action.delete')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default DisableProductModal;