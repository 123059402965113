import Modal from 'react-bootstrap/Modal';
import { createGroup } from '../../pages/Groups/GroupsActions'
import { Button, Form} from "react-bootstrap";
import { useState } from 'react';
import { success } from "../../components/Toasts"
import { useTranslation } from 'react-i18next';

function CreateGroupModal(props) {
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);
  
    const [createFormValue, setCreateFormValue] = useState({
      name: '',
      description: ''
    });

    const onCreateChange = (e) => {
      setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    };

    function resetModal() {
      setCreateFormValue({
        name: '',
        description: ''
      });

      setValidated(false);
    };
  
    function handleCreateGroup(e) {
      e.preventDefault();
  
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
  
      setValidated(true);
  
      createGroup(createFormValue.name, createFormValue.description).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
  
        success(t("success.CREATED_GROUP"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("page.create_group")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleCreateGroup}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label className="text-center">
                {t("input.name")}
              </Form.Label>
              <Form.Control type="text" name="name" placeholder={t("input.name")} onChange={onCreateChange} value={createFormValue.name} required/>
              <Form.Control.Feedback type="invalid">translated invalid name message</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label className="text-center">
                {t("input.description")}
              </Form.Label>
              <Form.Control type="text" name="description" placeholder={t("input.description")} onChange={onCreateChange} value={createFormValue.description}/>
              <Form.Control.Feedback type="invalid">translated invalid description message</Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.create")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CreateGroupModal;