import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar';

import { useLocation } from "react-router-dom";

export default function PageLayout() {
    const location = useLocation()

    return (
        <>
          {!location.pathname.startsWith("/login") &&
            !location.pathname.startsWith("/sign-up") &&
            !location.pathname.startsWith("/email-confirmation") &&
            !location.pathname.startsWith("/reset-password") &&
            !location.pathname.startsWith("/logout") 
            && 
            <NavBar />}

            <Outlet />
        </>);
}