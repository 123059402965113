import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupUsers(id, page, search) {
    let queryParameters = "?search=" + search + "&page=" + page
    
    return axios.get(API_URL + "groups/" + id + "/users" + queryParameters, {
        headers: authHeader()
    });
}

export function deposit(groupId, userId, amount, description) {
    return axios({
        method: 'post',
        url: API_URL + "groups/" + groupId + "/users/" + userId + "/deposit",
        headers: authHeader(),
        data: {
        amount,
        description
        }
    })
}

export function withdraw(groupId, walletId, amount, description) {
    return axios({
      method: 'post',
      url: API_URL + "groups/" + groupId + "/wallets/" + walletId + "/withdraw",
      headers: authHeader(),
      data: {
        amount,
        description
      }
    })
}