import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { parseWithDecimals } from '../../helpers/Number';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

function ShoppingCartListItem(props) {

    return (
    <ListGroup.Item className="align-items-start">
        <Row>
            <Col xs="4" style={{marginTop: "0.6rem"}}>
                <p><b>{props.product.quantity}x</b> {props.product.name}</p>
                <p>{props.product.description}</p>
            </Col>
            <Col xs="3" style={{textAlign: "right", marginTop: "0.6rem"}}>
                <p style={{marginBottom: "0"}}>€ {parseWithDecimals((props.product.quantity * props.product.price), 2)}</p>
            </Col>
            <Col xs="5" style={{textAlign: "right"}}>
                <Button variant="outline-secondary" className="mt-1" style={{marginRight: "0.5rem"}} onClick={() => props.actions.removeProduct(props.product)}>
                    <FontAwesomeIcon icon={faMinus} size="sm" />
                </Button>

                <Button variant="outline-secondary" className="mt-1" onClick={() => props.actions.addProduct(props.product)}>
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default ShoppingCartListItem;