import Modal from 'react-bootstrap/Modal';
import { createProduct } from '../ProductActions'
import { Button, Form} from "react-bootstrap";
import { useState } from 'react';
import { success } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

function CreateProductModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();
    const { listId } = useParams();

    const [validated, setValidated] = useState(false);
  
    const [createFormValue, setCreateFormValue] = useState({
      name: '',
      description: '',
      price: ''
    });

    const onCreateChange = (e) => {
      setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    };

    const onPriceChange = (e) => {
      const allowedChars = '0123456789.,';

      if (e.nativeEvent.data === null || allowedChars.includes(e.nativeEvent.data)) {
        setCreateFormValue({ ...createFormValue, "price": e.target.value.replace(',', '.')});
      }
    };

    function resetModal() {
      setCreateFormValue({
        name: '',
        description: '',
        price: ''
      });

      setValidated(false);
    };
  
    function handleCreateProduct(e) {
      e.preventDefault();
  
      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }
  
      createProduct(groupId, listId, createFormValue.name, createFormValue.description, createFormValue.price).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
  
        success(t("success.CREATED_PRODUCT"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("page.create_product")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Alert variant="danger">
          <Alert.Heading>{t("warning")}</Alert.Heading>
          <p>{t('warning_product_creation')}</p>
        </Alert>
          <Form noValidate validated={validated} onSubmit={handleCreateProduct}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label className="text-center">
                {t("input.name")}
              </Form.Label>
              <Form.Control type="text" name="name" placeholder={t("input.name")} onChange={onCreateChange} value={createFormValue.name} required/>
              <Form.Control.Feedback type="invalid">translated invalid name message</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label className="text-center">
                {t("input.description")}
              </Form.Label>
              <Form.Control type="text" name="description" placeholder={t("input.description")} onChange={onCreateChange} value={createFormValue.description}/>
              <Form.Control.Feedback type="invalid">translated invalid description message</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPrice">
              <Form.Label className="text-center">
                {t("input.price")}
              </Form.Label>
              <InputGroup>
                <InputGroup.Text id="price-addon">€</InputGroup.Text>
                <Form.Control placeholder={t('input.price')} aria-describedby="price-addon" onChange={onPriceChange} value={createFormValue.price} required/>
                <Form.Control.Feedback type="invalid">translated invalid withdraw message</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.create")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CreateProductModal;