import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getGroupScheduleOrderedProducts } from '../SchedulesActions';
import ListGroup from 'react-bootstrap/ListGroup';
import { formatDateTime } from '../../../helpers/DateTime'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { parseWithDecimals } from '../../../helpers/Number';

function DetailsScheduleModal(props) {
    const { t } = useTranslation();

    const { groupId } = useParams();
  
    const [totalCost, setTotalCost] = useState();
    const [orderedProducts, setOrderedProducts] = useState([]);

    useEffect(() => {
      setTotalCost()
      setOrderedProducts([])

      if (props.schedule) {
        getOrderedProducts(groupId, props.schedule.id);
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.schedule]);

    function getOrderedProducts(groupId, scheduleId) {
      getGroupScheduleOrderedProducts(groupId, scheduleId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.products && responseData.products.length < 1) {
          setTotalCost();
          setOrderedProducts([]);

          return;
        }
      
        setTotalCost(responseData.totalCost)
        setOrderedProducts(responseData.products);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.schedule && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.schedule.productListName}</h1>
              <p>{props.schedule.productListDescription}</p>

              <p><span style={{fontWeight: "bold"}}>{t('input.date_time_limit')}</span><br/>{formatDateTime(props.schedule.dateTimeLimit)}</p>

              <p style={{marginBottom: "0"}}><span style={{fontWeight: "bold"}}>{t('ordered_products')}</span></p>
              <div style={{width: "20rem", margin: "auto"}}>
                <ListGroup variant="flush">
                {orderedProducts && orderedProducts.map((orderedProduct) => {
                    return <ListGroup.Item><b>{orderedProduct.quantity}x</b> {orderedProduct.name} €{parseWithDecimals(orderedProduct.price, 2)} {orderedProduct.quantity > 1 ? "- (€" + parseWithDecimals(orderedProduct.quantity * orderedProduct.price, 2) + ")" : null}</ListGroup.Item>
                })}

                { totalCost && (totalCost > 0) &&
                  <ListGroup.Item><b>{t('total')}</b> € {parseWithDecimals(totalCost, 2)}</ListGroup.Item>
                }
                </ListGroup>

                {orderedProducts.length < 1 &&
                  <p>{t('no_ordered_products')}</p>
                }
              </div>
          </Col>
        </Row>
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsScheduleModal;