import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom";

function GroupListItem(props) {

    let balance = props.group.balance;

    return (
    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
        <Link to={"/groups/" + props.group.id} style={{textDecoration: "none", display: "contents", color: "black"}}>
        <div className="ms-2 me-auto">
        <div className="fw-bold">{props.group.name}</div>
        {props.group.description}{!props.group.description && <br />}
        </div>
        <Badge bg={(balance < 0 && "danger") 
                || (balance === 0 && "secondary") 
                || (balance > 0 && balance < 10 && "warning") 
                || (balance >= 10 && "success")}>
        € {(Math.round(balance * 100) / 100).toFixed(2)}
        </Badge>
        </Link>
    </ListGroup.Item>);
}

export default GroupListItem;