import { Col, Row, Container, Card} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { useState, useEffect } from 'react';

import { getUserRoles } from '../pages/Groups/GroupsActions'

import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import logo from '../images/lunchcash-logo.png';

import { useTranslation } from 'react-i18next';

function NavBar() {
    const { t } = useTranslation();

    const { groupId } = useParams();

    const [inGroup, setInGroup] = useState(false);
    const [isManager, setIsManager] = useState(false);

    useEffect(() => {
        setInGroup(groupId === undefined ? false : true)

        if (groupId !== undefined) {
            getRoles(groupId)
        }
    }, [groupId]);

    function getRoles(id) {
        getUserRoles(id).then(response => {
          if(response.status !== 200) {
            return;
          }
    
          if (response.data.errors) {
            // NOP
            return;
          }

          let responseData = response.data.data;

          responseData.roles.forEach((role) => {
            if (role.id <= 2) {
                setIsManager(true);
                return;
            }
          })
        }).catch(error => {
          if (!error.response) {
            return;
          }

          // NOP
        });
      }

    return(
    <Container>
        <Row className="d-flex justify-content-center align-items-end mb-3" style={{height: "20vh"}}>
            <Col md={8} lg={6} xs={12}>
                <Card>
                <Card.Body>

                    <Navbar>
                    <Navbar.Brand>
                        <Link to="/groups">
                            <img src={logo} style={{width: "3rem"}} alt=""/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            {inGroup && 
                            <Nav.Item>
                                <Link className="nav-link" to={"/groups/" + groupId}>Home</Link>
                            </Nav.Item>
                            }

                            {inGroup && isManager && 
                                <NavDropdown title={t('group')} id="nav-dropdown" className="ml-auto">
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/users"}>{t('page.users')}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/tokens"}>{t('page.tokens')}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/product-lists"}>{t('page.product_lists')}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/schedules"}>{t('page.schedules')}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/orders"}>{t('page.orders')}</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to={"groups/" + groupId + "/settings"}>{t('page.settings')}</NavDropdown.Item>
                                </NavDropdown>
                            }

                            <NavDropdown title="Account" id="nav-dropdown">
                                {inGroup &&
                                    <>
                                        <NavDropdown.Item as={Link} to={"groups/" + groupId + "/wallet"}>{t('page.wallet')}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"groups/" + groupId + "/my-orders"}>{t('page.orders')}</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </>
                                }
                                <NavDropdown.Item as={Link} to={"settings"}>{t('page.settings')}</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Item>
                                <Link className="nav-link" to="/logout"><FontAwesomeIcon icon={faRightFromBracket} size="lg" /></Link>
                            </Nav.Item>

                        </Nav>
                    </Navbar.Collapse>
                    </Navbar>
                
                </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>);
}

export default NavBar;