import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { zeroPad } from '../../helpers/Number'

import { getTranslatedWeekday } from '../../helpers/DateTime';

function UpcomingScheduleListItem(props) {
    const { t } = useTranslation();

    const dateTimeLimit = new Date(props.schedule.dateTimeLimit);

    const [timeLeft, setTimeLeft] = useState();

    useEffect(() => {
        setTimeLeft(calculateTimeLeft(dateTimeLimit));

        setInterval(() => setTimeLeft(calculateTimeLeft(dateTimeLimit)), 1000);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function calculateTimeLeft(dateTime) {
        const difference = dateTime - new Date();
        let timeLeft;
      
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }

        return timeLeft;
    }

    return (
    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
        <Link to={"/groups/" + props.group + "/schedule/" + props.schedule.id + "/order"} style={{textDecoration: "none", display: "contents", color: "black"}}>
        <div className="ms-2 me-auto">
            <div className="fw-bold">{props.schedule.productListName}</div>

            {props.schedule.productListDescription}
        </div>
        <ListGroup style={{width: "9rem"}}>
            {timeLeft && 
            <ListGroup.Item className='border-0 p-0'><Badge bg="secondary">{getTranslatedWeekday(t, dateTimeLimit.getDay() > 0 ? dateTimeLimit.getDay() : 7)}{' '}{dateTimeLimit.getDate() + "-" + (dateTimeLimit.getMonth() + 1) + "-" + dateTimeLimit.getFullYear()}</Badge></ListGroup.Item>
            }
            <ListGroup.Item className='border-0 p-0'>
                <Badge bg={ timeLeft && ((timeLeft.days > 0 && "success") || (timeLeft.hours > 1 && "warning") || (timeLeft.hours < 2 && "danger"))}>
                {timeLeft && timeLeft.days > 0 && timeLeft.days + " " + t("days") }
                {timeLeft && timeLeft.days < 1 && zeroPad(timeLeft.hours) + ":" + zeroPad(timeLeft.minutes) + "." + zeroPad(timeLeft.seconds) }
                </Badge>

                { !timeLeft && 
                <Badge bg="danger">
                {t('action.expired')}
                </Badge>
                }
            </ListGroup.Item>
        </ListGroup>
        </Link>
    </ListGroup.Item>);
}

export default UpcomingScheduleListItem;