import axios from "axios";

import { authHeader } from '../services/AuthHeaderService';

import { AUTH_URL } from "./ApiConstants";

const Buffer = require('buffer/').Buffer 

export function login(email, password) {
  return axios.post(AUTH_URL + "login", {
      email,
      password
    });
}

export function logout() {
  return axios({
    method: 'post',
    url: AUTH_URL + "logout",
    headers: authHeader()
  })
}

export function register(firstname, lastname, email, password, language) {
  return axios.post(AUTH_URL + "register", {
    firstname,
    lastname,
    email,
    password,
    language
  });
}

export function resetPassword(code, password) {
  return axios({
    method: 'put',
    url: AUTH_URL + "reset-password",
    data: {
      code,
      password
    }
  })
}

export function requestResetPassword(email) {
  return axios({
    method: 'post',
    url: AUTH_URL + "reset-password",
    data: {
      email
    }
  })
}

export function getCurrentToken() {
  return localStorage.getItem('token');
}

export function getCurrentTokenUser() {
  return parseJwt(localStorage.getItem('token'));
}

// Helper functions
function parseJwt (token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}