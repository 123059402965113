import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupUsers } from './UsersActions'

import CustomPagination from '../../components/CustomPagination'

import { useTranslation } from 'react-i18next';

import UserListItem from "../../components/List/UserListItem";

import KickUserModal from "./modals/KickUserModal";
import DetailsUserModal from "./modals/DetailsUserModal";
import DepositUserModal from "./modals/DepositUserModal";
import WithdrawUserModal from "./modals/WithdrawUserModal";
import RolesUserModal from "./modals/RolesUserModal";

function UsersPage() {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [searchValue, setSearchValue] = useState("");

    const [page, setPage] = useState(1);

    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState();

    const [modalUser, setModalUser] = useState();
    const [kickModalShow, setKickModalShow] = useState(false);
    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [depositModalShow, setDepositModalShow] = useState(false);
    const [withdrawModalShow, setWithdrawModalShow] = useState(false);
    const [rolesModalShow, setRolesModalShow] = useState(false);

    let userActions = {
      kick: (user) => {
        setModalUser(user)
        setKickModalShow(true)
      },
      details: (user) => {
        setModalUser(user)
        setDetailsModalShow(true)
      },
      deposit: (user) => {
        setModalUser(user)
        setDepositModalShow(true)
      },
      withdraw: (user) => {
        setModalUser(user)
        setWithdrawModalShow(true)
      },
      roles: (user) => {
        setModalUser(user)
        setRolesModalShow(true)
      },
    }

    function refreshList() {
      getUsers(groupId, page, searchValue);
    }

    function onUserKicked(refresh) {
      if (refresh) {
        refreshList();
      }

      setKickModalShow(false);
    }

    function onUserDeposit(refresh) {
      if (refresh) {
        refreshList();
      }

      setDepositModalShow(false);
    }

    function onUserWithdrawal(refresh) {
      if (refresh) {
        refreshList();
      }

      setWithdrawModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getUsers(groupId, page, searchValue);
    }

    useEffect(() => {
        getUsers(groupId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUsers(id, page, search) {
      getGroupUsers(id, page, search).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.users && responseData.users.length < 1) {
          setTotalUsers(0);
          setUsers([]);

          return;
        }
      
        setTotalUsers(responseData.total);
        setUsers(responseData.users);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getUsers(groupId, 1, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");
      getUsers(groupId, 1, "");
    }

    return (
        <div>
          <KickUserModal show={kickModalShow} onHide={onUserKicked} user={modalUser} />
          <DetailsUserModal show={detailsModalShow} onHide={() => setDetailsModalShow(false)} user={modalUser} />
          <DepositUserModal show={depositModalShow} onHide={onUserDeposit} user={modalUser} />
          <WithdrawUserModal show={withdrawModalShow} onHide={onUserWithdrawal} user={modalUser} />
          <RolesUserModal show={rolesModalShow} onHide={() => setRolesModalShow(false)} user={modalUser} />

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t("page.users")}</h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {users.length > 0 && 
                                    <ListGroup as="ul" key="users">
                                        {users && users.map((user) => {
                                            return <UserListItem group={groupId} user={user} key={user.id} actions={userActions}/>
                                        })}
                                    </ListGroup>
                                }

                                {totalUsers !== undefined && users.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalUsers} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {users.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_users')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default UsersPage;