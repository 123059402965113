import { useSearchParams, useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";

import { resetPassword } from "../../services/AuthActions";

import { useState, useEffect } from 'react';

import { error, success } from "../../components/Toasts"

import { containsError } from '../../services/ErrorResolver';

import logo from '../../images/lunchcash-logo.png';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);

    const [passwordFormValue, setPasswordFormValue] = useState("");
    const [confirmPasswordFormValue, setConfirmPasswordFormValue] = useState("");

    let [searchParams] = useSearchParams();
    let [code, setCode] = useState("");

    useEffect(() => {
      setCode(searchParams.get("code"));
      
      // If no code is found, redirect to login
      if (searchParams.get("code") === null || searchParams.get("code").length < 1) {
        navigate('/login', { replace: true });
      }
    }, [navigate, searchParams]);

    function handleResetPassword(e) {
      e.preventDefault();

      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }

      if (passwordFormValue !== confirmPasswordFormValue) {
        error(t("error.PASSWORDS_NO_MATCH"));
        return;
      }

      if (code.length < 1) {
        error(t("error.CODE_EXPIRED"));
        return;
      }
  
      resetPassword(code, passwordFormValue).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "CODE_EXPIRED")) {
            error(t("error.CODE_EXPIRED"));
          }

          if (containsError(errors, "PASSWORD_REQUIREMENTS_NOT_MET")) {
            error(t("error.PASSWORD_REQUIREMENTS_NOT_MET"));
          }
          return;
        }

        success(t("success.PASSWORD_RESET"));

        navigate("/login");
      }).catch(error => {
        if (!error.response) {
          return;
        }
      });
    }

    return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                <div className="mb-4 d-grid justify-content-center">
                    <img src={logo} style={{width: "10rem"}} alt=""/>
                  </div>
                  <div className="mb-3">
                    <Form noValidate validated={validated} onSubmit={handleResetPassword}>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>
                          {t("input.password")}
                        </Form.Label>
                        <Form.Control type="password" name="password" placeholder={t("input.password")} onChange={e => setPasswordFormValue(e.target.value)} value={passwordFormValue} required/>
                        <Form.Control.Feedback type="invalid">translated invalid password message</Form.Control.Feedback>
                      </Form.Group>

                      {passwordFormValue.length > 0 && <p style={{color: passwordFormValue.length > 6 && passwordFormValue.length < 21 ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.length")}</p>}
                      {passwordFormValue.length > 0 && <p style={{color: /[a-z]/.test(passwordFormValue) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.lower_case")}</p>}
                      {passwordFormValue.length > 0 && <p style={{color: /[A-Z]/.test(passwordFormValue) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.upper_case")}</p>}
                      {passwordFormValue.length > 0 && <p style={{color: /[0-9]/.test(passwordFormValue) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.number")}</p>}
                      {passwordFormValue.length > 0 && <p style={{color: /[^a-zA-Z0-9]/.test(passwordFormValue) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.special_character")}</p>}

                      {passwordFormValue !== confirmPasswordFormValue && passwordFormValue.length > 0 && <p style={{color: "red"}}>{t("error.PASSWORDS_NO_MATCH")}</p>}
                      {passwordFormValue === confirmPasswordFormValue && passwordFormValue.length > 0 &&  <p style={{color: "green"}}>{t("success.PASSWORDS_MATCH")}</p>}

                      <Form.Group className="mb-3" controlId="formConfirmPassword">
                        <Form.Label>
                          {t("input.confirm_password")}
                        </Form.Label>
                        <Form.Control type="password" placeholder={t("input.confirm_password")} onChange={e => setConfirmPasswordFormValue(e.target.value)} value={confirmPasswordFormValue} required/>
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          {t("action.reset_password")}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    );
}

export default ResetPassword;