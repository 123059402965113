import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getUserDetails() {
  return axios.get(API_URL + "user/", {
    headers: authHeader()
  });
}

export function update(firstName, lastName, language) {
  return axios({
    method: 'post',
    url: API_URL + "user/",
    headers: authHeader(),
    data: {
      firstName,
      lastName,
      language
    }
  })
}