import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react';

import { confirmEmail } from './EmailConfirmationActions'
import gif from '../../images/sheep-okay.gif';

function EmailConfirmation() {
    let [searchParams] = useSearchParams();

    const [loginUrl, setLoginUrl] = useState("");

    useEffect(() => {
        setLoginUrl(`${window.location.origin}/login`);

        const confirmationCode = searchParams.get("code");

        if (confirmationCode !== null) {
            confirmEmail(confirmationCode);
        }
      }, [searchParams]);

    return (
        <>
            <div className="mt-4 d-grid justify-content-center">
                <h1 style={{textAlign: "center"}}>Good job!</h1>
                <img src={gif} alt="this slowpoke moves" width="500" />
                <p style={{textAlign: "center"}}>You can now login <a href={loginUrl}><b>here</b></a> or close this window</p>
            </div>
        </>
    );
}

export default EmailConfirmation;