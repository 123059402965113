import Modal from 'react-bootstrap/Modal';
import { cancelGroupSchedule } from '../SchedulesActions'
import { Button, Form, Col, Row } from "react-bootstrap";
import { success } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { formatDateTime } from '../../../helpers/DateTime'
import { useState } from 'react';

function CancelScheduleModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [processingRequest, setProcessingRequest] = useState(false);
  
    function handleCancelSchedule(e) {
      e.preventDefault();

      setProcessingRequest(true);
  
      cancelGroupSchedule(groupId, props.schedule.id).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
  
        success(t("success.SCHEDULE_CANCELLED"))
  
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      }).finally(() => {
        setProcessingRequest(false)
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('action.cancel')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.schedule && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.schedule.productListName}</h1>
              <p>{props.schedule.productListDescription}</p>

              <p><span style={{fontWeight: "bold"}}>{t('input.date_time_limit')}</span><br/>{formatDateTime(props.schedule.dateTimeLimit)}</p>
          </Col>
        </Row>
        }

          <Form noValidate onSubmit={handleCancelSchedule}>
            <Row className="justify-content-center text-center mt-5">
              <Col>
                <Button variant="danger" type="submit" size="lg" disabled={processingRequest}>
                  {t('action.cancel')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CancelScheduleModal;