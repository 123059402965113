import Modal from 'react-bootstrap/Modal';
import { createGroupToken } from '../TokensActions'
import { Button, Form} from "react-bootstrap";
import { useState } from 'react';
import { success } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import moment from 'moment';
import InputGroup from 'react-bootstrap/InputGroup';

function CreateTokenModal(props) {
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);

    const { groupId } = useParams();
  
    const [createFormValue, setCreateFormValue] = useState({
      maxUsage: '',
      dateTimeLimit: ''
    });

    const onCreateChange = (e) => {
      setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    };

    function resetModal() {
      setCreateFormValue({
        maxUsage: '',
        dateTimeLimit: ''
      });

      setValidated(false);
    };
  
    function handleCreateToken(e) {
      e.preventDefault();
  
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
  
      setValidated(true);
      
      let dateTimeLimit = "";
      if (createFormValue.dateTimeLimit.length > 1) {
        dateTimeLimit = moment(createFormValue.dateTimeLimit).local().toISOString()
      }

      let maxUsage = createFormValue.maxUsage;
      if (createFormValue.maxUsage.length < 1) {
        maxUsage = 0
      }
  
      createGroupToken(groupId, maxUsage, dateTimeLimit).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.tokens && responseData.tokens.length < 1) {
          // NOP
          return;
        }
      
        success(t("success.CREATED_TOKEN"))
  
        resetModal();
        props.onHide(true, responseData.token);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleClear(e) {
      setCreateFormValue({ ...createFormValue, dateTimeLimit: '' });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("page.create_token")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleCreateToken}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label className="text-center">
                {t("input.max_usages")} ({t("input_instruction.max_usages")})
              </Form.Label>
              <Form.Control type="number" name="maxUsage" placeholder={t("input.max_usages")} onChange={onCreateChange} value={createFormValue.maxUsage} />
              <Form.Control.Feedback type="invalid">translated invalid max usage</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label className="text-center">
              {t("input.date_time_limit")} ({t("input_instruction.date_time_limit")})
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="datetime-local" name="dateTimeLimit" placeholder={t("input.date_time_limit")} onChange={onCreateChange} value={createFormValue.dateTimeLimit} aria-describedby="datetime-clear-button" />

                <Button variant="secondary" id="datetime-clear-button" onClick={(e) => handleClear(e)}>{t('action.clear')}</Button>

                <Form.Control.Feedback type="invalid">translated invalid date time limit</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                {t("action.create")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CreateTokenModal;