import { Link, useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";

import { register, getCurrentToken } from "../../services/AuthActions";

import { useState, useEffect } from 'react';

import { error, success } from "../../components/Toasts"

import { containsError } from '../../services/ErrorResolver';

import logo from '../../images/lunchcash-logo.png';
import { useTranslation } from 'react-i18next';

function Register() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);

    const [registerFormValue, setRegisterFormValue] = useState({
      email: '',
      firstname: '',
      language: 'en',
      lastname: '',
      password: ''
    });

    // Array of available languages, in the future this should be fetched from the backend
    const [availableLanguages, setAvailableLanguages] = useState([ // eslint-disable-line
      {value: "en", label: "English"},
      {value: "nl", label: "Nederlands"},
    ]);

    const onRegisterChange = (e) => {
      setRegisterFormValue({ ...registerFormValue, [e.target.name]: e.target.value });
    };

    const [confirmPasswordFormValue, setConfirmPasswordFormValue] = useState("");

    useEffect(() => {
      if (getCurrentToken()) {
        navigate("/groups");
      }
    }, [navigate]);

    function handleRegister(e) {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }

      setValidated(true);

      if (registerFormValue.password !== confirmPasswordFormValue) {
        error(t("error.PASSWORDS_NO_MATCH"));
        return;
      }
  
      register(registerFormValue.firstname, registerFormValue.lastname, registerFormValue.email, registerFormValue.password, registerFormValue.language).then(response => {
        if(response.status !== 200) {
          return;
        }

        if (response.data.errors) {
          handleErrors(response.data.errors);
          return;
        }

        success(t("success.ACCOUNT_CREATED"));
        success(t("success.EMAIL_CONFIRMATION"));

        navigate("/login");
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // Bad request (Frontend validation)
      });
    }

    function handleErrors(errors) {
      if (containsError(errors, "PASSWORD_REQUIREMENTS_NOT_MET")) {
        error(t("error.PASSWORD_REQUIREMENTS_NOT_MET"));
      }
    }

    return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                <div className="mb-4 d-grid justify-content-center">
                    <img src={logo} style={{width: "10rem"}} alt=""/>
                  </div>
                  <div className="mb-3">
                    <Form noValidate validated={validated} onSubmit={handleRegister}>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label className="text-center">
                          {t("input.email")}
                        </Form.Label>
                        <Form.Control type="email" name="email" placeholder={t("input.email")} onChange={onRegisterChange} value={registerFormValue.email} required/>
                        <Form.Control.Feedback type="invalid">translated invalid email message</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label className="text-center">
                          {t("input.first_name")}
                        </Form.Label>
                        <Form.Control type="text" name="firstname" placeholder={t("input.first_name")} onChange={onRegisterChange} value={registerFormValue.firstname} required/>
                        <Form.Control.Feedback type="invalid">translated invalid firstname message</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label className="text-center">
                          {t("input.last_name")}
                        </Form.Label>
                        <Form.Control type="text" name="lastname" placeholder={t("input.last_name")} onChange={onRegisterChange} value={registerFormValue.lastname} required/>
                        <Form.Control.Feedback type="invalid">translated invalid lastname message</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formLanguage">
                        <Form.Label className="text-center">
                          {t("input.select_language")}
                        </Form.Label>
                        <Form.Select aria-label="Language" name="language" onChange={onRegisterChange} required>
                          {availableLanguages.length > 0 && availableLanguages.map((language) => {
                              return <option key={language.value} value={language.value}>{language.label}</option>
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">translated invalid language</Form.Control.Feedback>
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>
                          {t("input.password")}
                        </Form.Label>
                        <Form.Control type="password" name="password" placeholder={t("input.password")} onChange={onRegisterChange} value={registerFormValue.password} required/>
                        <Form.Control.Feedback type="invalid">translated invalid password message</Form.Control.Feedback>
                      </Form.Group>

                      {registerFormValue.password.length > 0 && <p style={{color: registerFormValue.password.length > 6 && registerFormValue.password.length < 21 ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.length")}</p>}
                      {registerFormValue.password.length > 0 && <p style={{color: /[a-z]/.test(registerFormValue.password) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.lower_case")}</p>}
                      {registerFormValue.password.length > 0 && <p style={{color: /[A-Z]/.test(registerFormValue.password) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.upper_case")}</p>}
                      {registerFormValue.password.length > 0 && <p style={{color: /[0-9]/.test(registerFormValue.password) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.number")}</p>}
                      {registerFormValue.password.length > 0 && <p style={{color: /[^a-zA-Z0-9]/.test(registerFormValue.password) ? "green" : "red", marginBottom: "0"}}>{t("input.password_requirement.special_character")}</p>}

                      {registerFormValue.password !== confirmPasswordFormValue && registerFormValue.password.length > 0 && <p style={{color: "red"}}>{t("error.PASSWORDS_NO_MATCH")}</p>}
                      {registerFormValue.password === confirmPasswordFormValue && registerFormValue.password.length > 0 &&  <p style={{color: "green"}}>{t("success.PASSWORDS_MATCH")}</p>}

                      <Form.Group className="mb-3" controlId="formConfirmPassword">
                        <Form.Label>
                          {t("input.confirm_password")}
                        </Form.Label>
                        <Form.Control type="password" placeholder={t("input.confirm_password")} onChange={e => setConfirmPasswordFormValue(e.target.value)} value={confirmPasswordFormValue} required/>
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          {t("action.sign_up")}
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        {t("already_have_account")}{" "}
                        <Link to="/login">{t("action.login")}</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    );
}

export default Register;