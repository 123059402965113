import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getCurrentToken, getCurrentTokenUser } from '../services/AuthActions';

import { warning } from "../components/Toasts"

import { useTranslation } from 'react-i18next';
 
const RouteGuard = ({ children }) => {
    const { t } = useTranslation();

    let location = useLocation();
    let token = getCurrentToken();

    if (!token) {
      warning(t("success.LOGGED_OUT"));
      return redirectToLogin(location);
    }

    if (token) {
      let user = getCurrentTokenUser();

      if (user.exp < getUnixTimestamp()) {
        localStorage.removeItem("token");

        warning(t("success.LOGGED_OUT"));

        return redirectToLogin(location);
      }
    }
  
    return children;
}

function redirectToLogin(location) {
  return <Navigate to="/login" state={{ from: location }} />;
}

function getUnixTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export default RouteGuard;