import axios from 'axios';
import { warning } from "../components/Toasts"

export function containsError(array, errorName) {
  return array.findIndex(error => error.code === errorName) > -1;
}

const errorHandler = (error) => {
  if (error.config.url.includes("my-roles")) {
    // Ignore my-roles url requests for error handling
    return
  }


  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 401) {
    warning("Authorization expired, please refresh...")
  }
  
  if (statusCode === 403) {
    warning("Insufficient permissions")
  }
}

axios.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorHandler(error);

  return Promise.reject(error);
})