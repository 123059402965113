import Modal from 'react-bootstrap/Modal';
import { cancelGroupOrder } from '../OrdersActions'
import { Button, Form, Col, Row } from "react-bootstrap";
import { success, error } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { formatDateTime } from '../../../helpers/DateTime'
import { parseWithDecimals } from '../../../helpers/Number';
import { containsError } from '../../../services/ErrorResolver';

function CancelOrderModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();

    function handleCancelOrder(e) {
      e.preventDefault();
  
      cancelGroupOrder(groupId, props.order.orderId).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "SCHEDULE_EXPIRED")) {
            error(t("error.SCHEDULE_EXPIRED_CANCEL"));
          }
          return;
        }
  
        success(t("success.ORDER_CANCELLED"))
  
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }
  
        // NOP
      });
    }
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('action.cancel')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.order && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.order.productListName}</h1>

              <p><span style={{fontWeight: "bold"}}>{t('user')}</span><br/>{props.order.firstname + ' ' + props.order.lastname}</p>
              <p><span style={{fontWeight: "bold"}}>{t('email')}</span><br/>{props.order.email}</p>
              <p><span style={{fontWeight: "bold"}}>{t('cost')}</span><br/>€ {parseWithDecimals(props.order.orderCost, 2)}</p>
              <p><span style={{fontWeight: "bold"}}>{t('created')}</span><br/>{formatDateTime(props.order.orderCreated)}</p>
          </Col>
        </Row>
        }

          <Form noValidate onSubmit={handleCancelOrder}>
            <Row className="justify-content-center text-center mt-5">
              <Col>
                <Button variant="danger" type="submit" size="lg">
                  {t('action.cancel')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CancelOrderModal;