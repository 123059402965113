import { Button, Col, Row, Container, Card } from "react-bootstrap";

import { useState, useEffect } from 'react';

import { getGroups } from './GroupsActions'

import JoinGroupModal from "../../components/modals/JoinGroupModal";

import GroupListItem from '../../components/List/GroupListItem';

import ListGroup from 'react-bootstrap/ListGroup';
import CreateGroupModal from "../../components/modals/CreateGroupModal";
import { useTranslation } from 'react-i18next';

function GroupsPage() {
    const { t } = useTranslation();

    const [groups, setGroups] = useState([]);
    const [joinModalShow, setJoinModalShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    
    useEffect(() => {
        getJoinedGroups();
    }, []);

    function onJoinedGroup(joined) {
      if (joined) {
        getJoinedGroups();
      }

      setJoinModalShow(false)
    }

    function onCreateGroup(joined) {
      if (joined) {
        getJoinedGroups();
      }

      setCreateModalShow(false)
    }

    function getJoinedGroups() {
        getGroups().then(response => {
          if(response.status !== 200) {
            return;
          }
    
          if (response.data.errors) {
            // NOP
            return;
          }

          let responseData = response.data.data;
          if (responseData.groups && responseData.groups.length < 1) {
            // NO JOINED GROUPS ERROR
            return;
          }

          setGroups(responseData.groups);
        }).catch(error => {
          if (!error.response) {
            return;
          }

          // NOP
        });
      }

    return (
        <div>
          <JoinGroupModal show={joinModalShow} onHide={onJoinedGroup} />
          <CreateGroupModal show={createModalShow} onHide={onCreateGroup} />

            <Container>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <Card className="shadow">
                            <Card.Body>
                                <Row>
                                  <Col>
                                  <h1>{t("page.groups")}</h1>
                                  </Col>
                                  <Col xs="auto">
                                    <div className="justify-content-center align-items-center mt-1 mb-2 d-flex">
                                      <Button style={{marginRight: '0.5rem'}} variant="success" onClick={() => setCreateModalShow(true)}>{t("action.create")}</Button>
                                      <Button variant="primary" onClick={() => setJoinModalShow(true)}>{t("action.join")}</Button>
                                    </div>
                                  </Col>
                                </Row>
                                {groups.length > 0 && 
                                    <ListGroup as="ul" key="groups" style={{maxHeight: "20rem", overflow: "auto"}}>
                                        {groups && groups.map((group) => {
                                            return <GroupListItem group={group} key={group.id}/>
                                        })}
                                    </ListGroup>
                                }
                                
                                {(groups.length < 1 || !groups) && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t("no_groups")}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default GroupsPage;