import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupTokens } from './TokensActions'

import CustomPagination from '../../components/CustomPagination'

import { useTranslation } from 'react-i18next';

import TokenListItem from "../../components/List/TokenListItem";
import CreateTokenModal from "./modals/CreateTokenModal";
import DetailsTokenModal from "./modals/DetailsTokenModal";
import DisableTokenModal from "./modals/DisableTokenModal";

function TokensPage() {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [searchValue, setSearchValue] = useState("");

    const [page, setPage] = useState(1);

    const [tokens, setTokens] = useState([]);
    const [totalTokens, setTotalTokens] = useState();

    const [modalToken, setModalToken] = useState();

    const [createModalShow, setCreateModalShow] = useState(false);
    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [disableModalShow, setDisableModalShow] = useState(false);

    let userActions = {
      details: (token) => {
        setModalToken(token)
        setDetailsModalShow(true)
      },
      create: () => {
        setCreateModalShow(true)
      },
      disable: (token) => {
        setModalToken(token)
        setDisableModalShow(true)
      },
    }

    function refreshList() {
      getTokens(groupId, page, searchValue);
    }

    function onTokenCreate(created, token) {
      if (created) {
        refreshList();
      }

      if (token) {
        setModalToken(token)
        setDetailsModalShow(true)
      }

      setCreateModalShow(false);
    }

    function onTokenDisable(disabled) {
      if (disabled) {
        refreshList();
      }

      setDisableModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getTokens(groupId, page, searchValue);
    }

    useEffect(() => {
      getTokens(groupId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getTokens(id, page, search) {
      getGroupTokens(id, page, search).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.tokens && responseData.tokens.length < 1) {
          setTotalTokens(0);
          setTokens([]);

          return;
        }
      
        setTotalTokens(responseData.total);
        setTokens(responseData.tokens);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getTokens(groupId, 1, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");
      getTokens(groupId, 1, "");
    }

    return (
        <div>
            <CreateTokenModal show={createModalShow} onHide={onTokenCreate} />
            <DisableTokenModal show={disableModalShow} onHide={onTokenDisable} token={modalToken} />
            <DetailsTokenModal show={detailsModalShow} onHide={() => setDetailsModalShow(false)} token={modalToken} />

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{t('page.tokens')}
                                  <Button variant="primary" type="submit" style={{marginLeft: "0.6rem"}} onClick={() => setCreateModalShow(true)}>
                                    {t('action.create')}
                                  </Button>
                                </h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {tokens.length > 0 && 
                                    <ListGroup as="ul" key="tokens">
                                        {tokens && tokens.map((token) => {
                                            return <TokenListItem group={groupId} token={token} key={token.id} actions={userActions} />
                                        })}
                                    </ListGroup>
                                }

                                {totalTokens !== undefined && tokens.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalTokens} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {tokens.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>No tokens</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TokensPage;