import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupUser(groupId, userId) {
    return axios.get(API_URL + "groups/" + groupId + "/users/" + userId, {
        headers: authHeader()
    });
}

export function getGroupSchedule(groupId, scheduleId) {
    return axios.get(API_URL + "groups/" + groupId + "/schedules/" + scheduleId, {
        headers: authHeader()
    });
}

export function getGroupScheduleProducts(groupId, scheduleId) {
    return axios.get(API_URL + "groups/" + groupId + "/schedules/" + scheduleId + "/products", {
        headers: authHeader()
    });
}

export function orderGroupScheduleProducts(groupId, scheduleId, products, userId) {
    return axios({
        method: 'post',
        url: API_URL + "groups/" + groupId + "/schedules/" + scheduleId + "/orders",
        headers: authHeader(),
        data: {
            products,
            userId
        }
      })
}