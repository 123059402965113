import { Col, Row, Container, Card, CardGroup } from "react-bootstrap";

import { useParams } from "react-router-dom";

import { useState, useEffect } from 'react';

import { getUserWallet } from './WalletActions'

import { useTranslation } from 'react-i18next';

function WalletPage() {
    const { groupId } = useParams();
    const { t } = useTranslation();

    const [wallet, setWallet] = useState();
    
    useEffect(() => {
      getWallet(groupId);
    }, [groupId]);

    function getWallet(id) {
      getUserWallet(id).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }
        
        setWallet(response.data.data.wallet);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    return (
        <div>
            <Container>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <Card className="shadow">
                            <Card.Body>
                              <h1>{t('page.wallet')}</h1>
                                
                              <CardGroup>
                                <Card>
                                  <Card.Body>
                                    <Card.Title className="justify-content-center mt-1 d-flex">{t('wallet.balance')}</Card.Title>
                                    <Card.Text>
                                      <span className="justify-content-center mb-3 d-flex">
                                        € {wallet && (Math.round(wallet.availableBalance * 100) / 100).toFixed(2)}
                                      </span>

                                      <span className="text-center">
                                        {t('wallet.balance_explained')}
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                                <Card>
                                  <Card.Body>
                                    <Card.Title className="justify-content-center mt-1 d-flex">{t('wallet.pending')}</Card.Title>
                                    <Card.Text>
                                        <span className="justify-content-center mb-3 d-flex">
                                          € {wallet && (Math.round(wallet.reservedBalance * 100) / 100).toFixed(2)}
                                        </span>

                                        <span className="text-center">
                                          {t('wallet.pending_explained')}
                                        </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </CardGroup>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WalletPage;