import Modal from 'react-bootstrap/Modal';
import { getAvailableGroupRoles, getUserGroupRoles, addUserRole, removeUserRole } from '../../Groups/GroupsActions'
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

import { containsError } from './../../../services/ErrorResolver';
import { success, error } from "../../../components/Toasts"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

function RolesUserModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();

    let availableRolesCount = 0;

    const [availableRoles, setAvailableRoles] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    function refresh() {
      availableRolesCount = 0;
      getUserRoles(groupId, props.user.id);
    }

    useEffect(() => {
      if (props.show) {
        getAvailableRoles(groupId);
        refresh();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    function addRole(role) {
      addUserRole(groupId, props.user.id, role.id).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "USER_ROLE_DUPLICATE")) {
            error(t("error.USER_ROLE_DUPLICATE"));
          }

          if (containsError(errors, "CANNOT_UPDATE_OWN_ROLES")) {
            error(t("error.CANNOT_UPDATE_OWN_ROLES"));
          }

          return;
        }
      
        success("User is now " + role.name.charAt(0) + role.name.substring(1).toLowerCase())
        refresh();
      }).catch(errorResponse => {
        if (errorResponse.response.data.errors.length > 0) {
          let errors = errorResponse.response.data.errors;

          if (containsError(errors, "LACKING_PERMISSIONS")) {
            error(t("error.LACKING_PERMISSIONS"));
          }

          return;
        }
      });
    }

    function removeRole(role) {
      removeUserRole(groupId, props.user.id, role.id).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;
          if (containsError(errors, "CANNOT_UPDATE_OWN_ROLES")) {
            error(t("error.CANNOT_UPDATE_OWN_ROLES"));
          }

          if (containsError(errors, "LACKING_PERMISSIONS")) {
            error(t("error.LACKING_PERMISSIONS"));
          }
          
          return;
        }
      
        success("User is no longer " + role.name.charAt(0) + role.name.substring(1).toLowerCase())
        refresh();
      }).catch(errorResponse => {
        if (errorResponse.response.data.errors.length > 0) {
          let errors = errorResponse.response.data.errors;

          if (containsError(errors, "LACKING_PERMISSIONS")) {
            error(t("error.LACKING_PERMISSIONS"));
          }
          
          return;
        }
      });
    }

    function getUserRoles(groupId, userId) {
      getUserGroupRoles(groupId, userId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.roles && responseData.roles.length < 1) {
          setUserRoles([])

          return;
        }
      
        setUserRoles(responseData.roles);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getAvailableRoles(groupId) {
      getAvailableGroupRoles(groupId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.roles && responseData.roles.length < 1) {
          // NOP
          return;
        }
      
        setAvailableRoles(responseData.roles);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.roles')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.user && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.user.firstname}{' '}{props.user.lastname}</h1>
          </Col>
        </Row>
        }

        <Row className="justify-content-center mt-4">
        <Col md={4} className="offset-md-2">
            <span style={{fontWeight: "bold"}}>{t('user_roles')}</span>
            
            <ListGroup variant="flush">
              { userRoles.length > 0 &&
                <>
                  {userRoles && userRoles.map((role) => {
                    return <ListGroup.Item key={role.id}><FontAwesomeIcon icon={faMinus} size="lg" style={{color: "red", cursor: "pointer"}} onClick={() => removeRole(role)} /> {role.name.charAt(0) + role.name.substring(1).toLowerCase()}</ListGroup.Item>
                  })}
                </>
              }
              { userRoles.length < 1 &&
                t('no_roles')
              }
            </ListGroup>
            
          </Col>
          <Col md={4}>
            <span style={{fontWeight: "bold"}}>{t('available_roles')}</span>
            <ListGroup variant="flush">
              { availableRoles.length > 0 &&
                <>
                  {// eslint-disable-next-line array-callback-return
                  availableRoles && availableRoles.map((role) => {
                    
                    if (userRoles.filter(e => e.id === role.id).length < 1) {
                      availableRolesCount++;
                      return <ListGroup.Item key={role.id}><FontAwesomeIcon icon={faPlus} size="lg" style={{color: "green", cursor: "pointer"}} onClick={() => addRole(role)} /> {role.name.charAt(0) + role.name.substring(1).toLowerCase()}</ListGroup.Item>
                    }
                  })}
                  {availableRolesCount === 0 &&
                    t('no_roles')
                  }
                  
                </>
              }
            </ListGroup>
          </Col>
        </Row>

        </Modal.Body>
      </Modal>);
  }

  export default RolesUserModal;