import { Col, Row, Container, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form'

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";
import CustomPagination from '../../components/CustomPagination'

import { getGroupProductListById, getProductsById } from '../ProductLists/ProductListsActions'

import { useTranslation } from 'react-i18next';
import Placeholder from 'react-bootstrap/Placeholder';
import ProductListItem from "./ProductListItem";

import CreateProductModal from './modals/CreateProductModal'
import DetailsProductModal from "./modals/DetailsProductModal";
import DisableProductModal from "./modals/DisableProductModal";
import UpdateProductModal from "./modals/UpdateProductModal";

function ProductsPage() {
    const { t } = useTranslation();

    const { groupId } = useParams();
    const { listId } = useParams();

    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);

    const [productList, setProductList] = useState();
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState();

    const [modalProduct, setModalProduct] = useState();

    const [createModalShow, setCreateModalShow] = useState(false);
    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);
    const [disableModalShow, setDisableModalShow] = useState(false);

    let userActions = {
      create: () => {
        setCreateModalShow(true)
      },
      details: (product) => {
        setModalProduct(product)
        setDetailsModalShow(true)
      },
      update: (product) => {
        setModalProduct(product)
        setUpdateModalShow(true)
      },
      disable: (product) => {
        setModalProduct(product)
        setDisableModalShow(true)
      },
    }

    function refreshList() {
      getProducts(groupId, listId, page, searchValue);
    }

    function onProductCreate(created) {
      if (created) {
        refreshList();
      }

      setCreateModalShow(false);
    }

    function onProductDisable(disabled) {
      if (disabled) {
        refreshList();
      }

      setDisableModalShow(false);
    }

    function onProductUpdate(updated) {
      if (updated) {
        refreshList();
      }

      setUpdateModalShow(false);
    }

    function onPagination(page) {
      setPage(page);

      getProducts(groupId, listId, page, searchValue);
    }

    useEffect(() => {
      getProductList(groupId, listId)

      getProducts(groupId, listId, page, searchValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getProductList(groupId, listId) {
      getGroupProductListById(groupId, listId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.productList && responseData.productList.length < 1) {
          setProductList()

          return;
        }
      
        setProductList(responseData.productList);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function getProducts(groupId, listId, page, search) {
      getProductsById(groupId, listId, page, search).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.products && responseData.products.length < 1) {
          setProducts([])
          setTotalProducts()

          return;
        }
      
        setProducts(responseData.products);
        setTotalProducts(responseData.total)
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    function handleSearch(e) {
      e.preventDefault();
      setPage(1);
  
      getProducts(groupId, listId, page, searchValue);
    }

    function handleClear(e) {
      e.preventDefault();
  
      setPage(1);
      setSearchValue("");
      getProducts(groupId, listId, 1, searchValue);
    }

    return (
        <div>
          <CreateProductModal show={createModalShow} onHide={onProductCreate} />
          <DetailsProductModal show={detailsModalShow} onHide={() => setDetailsModalShow(false)} product={modalProduct} />
          <UpdateProductModal show={updateModalShow} onHide={onProductUpdate} product={modalProduct} />
          <DisableProductModal show={disableModalShow} onHide={onProductDisable} product={modalProduct} />

            <Container fluid>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12} className="pb-3">
                        <Card className="shadow">
                            <Card.Body>
                                <h1>{(productList && productList.name) || (!productList && <Placeholder xs={5} bg="light"/>)}{' '}{t('page.products')}
                                  <Button variant="primary" type="submit" style={{marginLeft: "0.6rem"}} onClick={() => setCreateModalShow(true)}>
                                    {t('action.create')}
                                  </Button>
                                </h1>
                              
                                <Form className="mb-3" noValidate onSubmit={handleSearch}>
                                  <Row>
                                    <Col>
                                      <Form.Control placeholder={t('action.search')} onChange={e => setSearchValue(e.target.value)} value={searchValue} />
                                    </Col>
                                    <Col xs="auto">
                                      <Button variant="primary" type="submit" style={{marginRight: "0.4rem"}}>
                                        {t('action.search')}
                                      </Button>
                                      <Button variant="secondary" onClick={(e) => handleClear(e)}>
                                        {t('action.clear')}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>

                                {products.length > 0 && 
                                    <ListGroup as="ul" key="productLists">
                                        {products && products.map((product) => {
                                            return <ProductListItem product={product} key={product.id} actions={userActions} />
                                        })}
                                    </ListGroup>
                                }

                                {totalProducts !== undefined && products.length > 0 && 
                                  <div className="justify-content-center mb-1 mt-4 d-flex">
                                      <CustomPagination total={totalProducts} currentPage={page} callback={onPagination} />
                                  </div>
                                }

                                {products.length < 1 && 
                                    <div className="justify-content-center align-items-center d-flex">
                                        <p style={{fontWeight: "bold"}}>{t('no_products')}</p>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProductsPage;