import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function createGroup(name, description) {
  return axios({
    method: 'post',
    url: API_URL + "groups",
    headers: authHeader(),
    data: {
      name,
      description
    }
  })
}

export function getGroupById(id) {
  return axios.get(API_URL + "groups/" + id, {
      headers: authHeader()
    });
}

export function getUserRoles(id) {
  return axios.get(API_URL + "groups/" + id + "/my-roles", {
      headers: authHeader()
    });
}

export function getGroups() {
    return axios.get(API_URL + "groups", {
        headers: authHeader()
      });
}

export function getTokenGroup(token) {
  return axios.get(API_URL + "tokens/" + token, {
      headers: authHeader()
    });
}

export function joinGroup(token) {
    return axios({
      method: 'post',
      url: API_URL + "tokens/" + token,
      headers: authHeader()
    })
}

export function kickFromGroup(groupId, userId) {
  return axios({
    method: 'post',
    url: API_URL + "groups/" + groupId + "/users/" + userId + "/kick",
    headers: authHeader()
  })
}

export function getAvailableGroupRoles(groupId) {
  return axios({
    method: 'get',
    url: API_URL + "groups/" + groupId + "/available-roles",
    headers: authHeader()
  })
}

export function getUserGroupRoles(groupId, userId) {
  return axios({
    method: 'get',
    url: API_URL + "groups/" + groupId + "/users/" + userId + "/roles",
    headers: authHeader()
  })
}

export function addUserRole(groupId, userId, roleId) {
  return axios({
    method: 'post',
    url: API_URL + "groups/" + groupId + "/users/" + userId + "/roles",
    headers: authHeader(),
    data: {
      roleId
    }
  })
}

export function removeUserRole(groupId, userId, roleId) {
  return axios({
    method: 'delete',
    url: API_URL + "groups/" + groupId + "/users/" + userId + "/roles",
    headers: authHeader(),
    data: {
      roleId
    }
  })
}