import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

import { parseWithDecimals } from '../../helpers/Number'

function ProductListItem(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="7">
                <div className="fw-bold">
                    <span style={{lineBreak: "anywhere"}}>{props.product.name}{' '}</span>
                </div>

                {props.product.description}
            </Col>
            <Col xs="3">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {t('action.price')}
                    </div>

                    € {parseWithDecimals(props.product.price, 2)}
                </div>
            </Col>
            <Col xs="1" style={{textAlign: "right"}}>
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                    <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                        <NavDropdown.Item onClick={() => props.actions.details(props.product)}>{t('page.details')}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => props.actions.update(props.product)}>{t('action.update')}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => props.actions.disable(props.product)}>{t('action.delete')}</NavDropdown.Item>
                    </NavDropdown>
                </Button>
            </Col>
        </Row>
    </ListGroup.Item>);
}

export default ProductListItem;