import { Col, Row, Container, Card, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { getGroupDetails } from './GroupSettingsActions'
import Form from 'react-bootstrap/Form'
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { error, success } from "../../components/Toasts"
import { containsError } from '../../services/ErrorResolver';
import { requestResetPassword } from '../../services/AuthActions';
import { update } from "./GroupSettingsActions";

function GroupSettingsPage() {
  const { t } = useTranslation();
  const { groupId } = useParams();

  const [groupFormValue, setGroupFormValue] = useState({
    name: '',
    description: '',
    allowDebt: false,
  });

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormChange = (e) => {
    setGroupFormValue({ ...groupFormValue, [e.target.name]: e.target.value });
  };

  const onCheckboxChange = (e) => {
    setGroupFormValue({ ...groupFormValue, [e.target.name]: e.target.checked });
  };

  function getDetails() {
    getGroupDetails(groupId).then((response) => {
      let responseData = response.data.data;

      if (responseData.group) {
        setGroupFormValue({
          name: responseData.group.name,
          description: responseData.group.description,
          allowDebt: responseData.group.allowDebt,
        });
      }
    }).catch(_ => {
      // NOP
    });
  }

  function handleOnSave(e) {
    e.preventDefault();
    // Save group settings

    update(groupId, groupFormValue.name, groupFormValue.description, groupFormValue.allowDebt).then((response) => {
      if (response.status !== 200) {
        return;
      }

      if (response.data.errors) {
        handleErrors(response.data.errors);
      }

      success(t("success.GROUP_UPDATED"));
    }).catch(error => {
      if (error.response.data.errors) {
        handleErrors(error.response.data.errors);
      }
    });
  }

  function handleErrors(errors) {
    if (containsError(errors, "MISSING_NAME")) {
      error(t("error.MISSING_NAME"));
    }
  }

  return (
    <div>
      <Container fluid>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12} className="pb-3">
            <Card className="shadow">
              <Card.Body>
                <h1>{t("group")} {t("page.settings")}</h1>

                <Form className="mb-2" noValidate onSubmit={handleOnSave}>
                  <Form.Label>{t('input.name')}</Form.Label>
                  <Form.Control type="text" placeholder={t('input.name')} name="name" value={groupFormValue.name} onChange={onFormChange} />

                  <Form.Label className="mt-4">{t('input.description')}</Form.Label>
                  <Form.Control type="text" placeholder={t('input.description')} name="description" value={groupFormValue.description} onChange={onFormChange} />

                  <Form.Group className="mt-4 mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" placeholder={t('input.allow_debt')} label={t('input.allow_debt')} name="allowDebt" checked={groupFormValue.allowDebt} onChange={onCheckboxChange} />
                  </Form.Group>

                  <div className="mt-4 d-flex flex-row-reverse">
                    <Button variant="primary" type="submit">
                      {t('action.save')}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default GroupSettingsPage;