import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getGroupTokens(id, page, search) {
    let queryParameters = "?search=" + search + "&page=" + page

    return axios.get(API_URL + "groups/" + id + "/tokens" + queryParameters, {
        headers: authHeader()
    });
}

export function createGroupToken(groupId, maxUsage, dateTimeLimit) {
    return axios({
      method: 'post',
      url: API_URL + "groups/" + groupId + "/tokens",
      headers: authHeader(),
      data: {
        maxUsage,
        dateTimeLimit
      }
    })
}

export function disableToken(groupId, tokenId) {
    return axios({
      method: 'delete',
      url: API_URL + "groups/" + groupId + "/tokens/" + tokenId,
      headers: authHeader()
    })
}