import Modal from 'react-bootstrap/Modal';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

function DetailsTokenModal(props) {
    const { t } = useTranslation();
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('page.details')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.token && 
        <>
          <Row className="justify-content-center text-center">
            <Col>
                <h1>{props.token.token}</h1>
                <p>{!props.token.expired &&
                    <Badge bg="success" style={{fontSize: "1rem"}}>
                        <FontAwesomeIcon icon={faCheck} size="lg" /> {t('action.active')}
                    </Badge>}

                    {props.token.expired &&
                    <Badge bg="danger" style={{fontSize: "1rem"}}>
                        <FontAwesomeIcon icon={faXmark} size="lg" /> {t('action.expired')}
                    </Badge>}
                    {' '}</p>
                <p><span style={{fontWeight: "bold"}}>{t('token.times_used')}</span><br/>{props.token.timesUsed}</p>

                {props.token.maxUsage > 0 &&
                  <p><span style={{fontWeight: "bold"}}>{t('token.max_usages')}</span><br/>{props.token.maxUsage}</p>
                }

                {props.token.dateTimeLimit !== null &&
                  <p><span style={{fontWeight: "bold"}}>{t('token.date_time_limit')}</span><br/>{moment(moment.utc(props.token.dateTimeLimit)).local().format('YYYY-MM-DD HH:mm')}</p>
                }
            </Col>
          </Row>
        </>
      
        }
        </Modal.Body>
      </Modal>);
  }

  export default DetailsTokenModal;