import Modal from 'react-bootstrap/Modal';
import { Button, Form, Col, Row } from "react-bootstrap";
import { error } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { formatDateTime } from '../../../helpers/DateTime'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { getGroupUsers } from '../SchedulesActions'

function OrderScheduleModal(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [selectFormValue, setSelectFormValue] = useState({
      userId: ''
    });

    const onSelectChange = (e) => {
      setSelectFormValue({ ...selectFormValue, [e.target.name]: e.target.value });
    };

    function handleOrderUserSchedule(e) {
      e.preventDefault();
      
      if (selectFormValue.userId === '' || selectFormValue.userId === null || selectFormValue.userId === undefined || selectFormValue.userId <= 0) {	
        error(t('error.SELECT_USER'));

        return;
      }

      return navigate("/groups/" + groupId + "/schedule/" + props.schedule.id + "/order/user/" + selectFormValue.userId);
    }

    function getUsers(id) {
      getGroupUsers(id).then(response => {
        if(response.status !== 200) {
          return;
        }
  
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.users && responseData.users.length < 1) {
          setUsers([]);

          return;
        }
      
        setUsers(responseData.users);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    useEffect(() => {
      getUsers(groupId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('action.order')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {props.schedule && 
        <Row className="justify-content-center text-center">
          <Col>
              <h1>{props.schedule.productListName}</h1>
              <p>{props.schedule.productListDescription}</p>

              <p><span style={{fontWeight: "bold"}}>{t('input.date_time_limit')}</span><br/>{formatDateTime(props.schedule.dateTimeLimit)}</p>
          </Col>
        </Row>
        }

          <Form noValidate onSubmit={handleOrderUserSchedule}>
            <Form.Group className="mb-3" controlId="formListId">
              <Form.Label className="text-center">
                {t("input.select_user")}
              </Form.Label>
              <Form.Select aria-label="User id" name="userId" required onChange={onSelectChange}>
                <option value="">{t("input.select_user")}</option>

                {users.length > 0 && users.filter(user => user.active === true).map((user) => {
                    return <option key={user.id} value={user.id}>{user.firstname + ' ' + user.lastname} - {user.email} - €{user.availableBalance}</option>
                })}

              </Form.Select>
              <Form.Control.Feedback type="invalid">translated invalid max usage</Form.Control.Feedback>
            </Form.Group>

            <Row className="justify-content-center text-center mt-5">
              <Col>
                <Button variant="primary" type="submit" size="lg">
                  {t('action.create_order')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default OrderScheduleModal;