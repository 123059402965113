import axios from "axios";

import { API_URL } from "../../services/ApiConstants";

import { authHeader } from '../../services/AuthHeaderService';

export function getUserWallet(id) {
    return axios.get(API_URL + "groups/" + id + "/wallet", {
        headers: authHeader()
    });
}

export function getUserWalletById(groupId, userId) {
    return axios.get(API_URL + "groups/" + groupId + "/users/" + userId + "/wallet", {
        headers: authHeader()
    });
}