import Modal from 'react-bootstrap/Modal';
import { createGroupSchedule, getGroupAvailableProductLists } from '../SchedulesActions'
import { Button, Form} from "react-bootstrap";
import { useState, useEffect } from 'react';
import { success, error } from "../../../components/Toasts"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import moment from 'moment';
import InputGroup from 'react-bootstrap/InputGroup';
import { containsError } from '../../../services/ErrorResolver';

function CreateScheduleModal(props) {
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);

    const { groupId } = useParams();

    const [productLists, setProductLists] = useState([]);

    const [processingRequest, setProcessingRequest] = useState(false);
  
    const [createFormValue, setCreateFormValue] = useState({
      listId: '',
      dateTimeLimit: ''
    });

    const onCreateChange = (e) => {
      setCreateFormValue({ ...createFormValue, [e.target.name]: e.target.value });
    };

    function resetModal() {
      setCreateFormValue({
        listId: '',
        dateTimeLimit: ''
      });

      setValidated(false);
    };

    function getAvailableProductLists() {
      getGroupAvailableProductLists(groupId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.productLists && responseData.productLists.length < 1) {
          setProductLists([])

          return;
        }
      
        setProductLists(responseData.productLists);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }
  
    function handleCreateSchedule(e) {
      e.preventDefault();

      setProcessingRequest(true)
      
      setValidated(true);

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
      }

      let dateTimeLimit = moment(createFormValue.dateTimeLimit).local().toISOString();
  
      createGroupSchedule(groupId, createFormValue.listId, dateTimeLimit).then(response => {
        if (response.data.errors) {
          let errors = response.data.errors;

          if (containsError(errors, "LIST_NOT_FOUND")) {
            error(t("error.LIST_NOT_FOUND"));
          }

          return;
        }
      
        success(t("success.CREATED_SCHEDULE"))
  
        resetModal();
        props.onHide(true);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      }).finally(() => {
        setProcessingRequest(false)
      });
    }

    function handleDateTimeClear(e) {
      setCreateFormValue({ ...createFormValue, dateTimeLimit: '' });
    }

    useEffect(() => {
      getAvailableProductLists();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("page.create_schedule")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleCreateSchedule}>
            <Form.Group className="mb-3" controlId="formListId">
              <Form.Label className="text-center">
                {t("input.select_list")}
              </Form.Label>
              <Form.Select aria-label="Product list id" name="listId" onChange={onCreateChange} required>
                <option value="">{t("input.select_list")}</option>

                {productLists.length > 0 && productLists.map((productList) => {
                    return <option key={productList.id} value={productList.id}>{productList.name}{productList.description && " - " + productList.description}</option>
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">translated invalid max usage</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formDateTime">
              <Form.Label className="text-center">
              {t("input.date_time_limit")}
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="datetime-local" name="dateTimeLimit" placeholder={t("input.date_time_limit")} onChange={onCreateChange} value={createFormValue.dateTimeLimit} aria-describedby="date-clear-button" required/>

                <Button variant="secondary" id="datetime-clear-button" onClick={(e) => handleDateTimeClear(e)}>{t('action.clear')}</Button>

                <Form.Control.Feedback type="invalid">translated invalid date time limit</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="d-grid">
              <Button variant="primary" type="submit" disabled={processingRequest}>
                {t("action.create")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>);
  }

  export default CreateScheduleModal;