import { Col, Row, Card, Button } from "react-bootstrap";

import { useState, useEffect } from 'react';

import ListGroup from 'react-bootstrap/ListGroup';

import { useParams } from "react-router-dom";

import { getGroupTemplates } from './TemplateActions'

import { useTranslation } from 'react-i18next';
import TemplateListItem from "./TemplateListItem";

import DetailsTemplateModal from './modals/DetailsTemplateModal'
import CreateTemplateModal from "./modals/CreateTemplateModal";
import DisableTemplateModal from "./modals/DisableTemplateModal";

function TemplateModule() {
    const { t } = useTranslation();

    const { groupId } = useParams();

    const [templates, setTemplates] = useState([]);

    const [modalTemplate, setModalTemplate] = useState();

    const [detailsModalShow, setDetailsModalShow] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [disableModalShow, setDisableModalShow] = useState(false);

    let userActions = {
      details: (template) => {
        setModalTemplate(template)
        setDetailsModalShow(true)
      },
      create: () => {
        setCreateModalShow(true)
      },
      disable: (template) => {
        setModalTemplate(template)
        setDisableModalShow(true)
      }
    }

    function refreshList() {
      getTemplates(groupId)
    }

    function onTemplateDetails() {
      setDetailsModalShow(false);
    }

    function onTemplateCreate(created) {
      if (created) {
        refreshList();
      }

      setCreateModalShow(false);
    }

    function onTemplateDisable(disabled) {
      if (disabled) {
        refreshList();
      }

      setDisableModalShow(false);
    }

    useEffect(() => {
      getTemplates(groupId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getTemplates(groupId) {
      getGroupTemplates(groupId).then(response => {
        if (response.data.errors) {
          // NOP
          return;
        }

        let responseData = response.data.data;
        if (responseData.templates && responseData.templates.length < 1) {
          setTemplates([])

          return;
        }
      
        setTemplates(responseData.templates);
      }).catch(error => {
        if (!error.response) {
          return;
        }

        // NOP
      });
    }

    return (
      <>
      <DetailsTemplateModal show={detailsModalShow} onHide={onTemplateDetails} template={modalTemplate} />
      <CreateTemplateModal show={createModalShow} onHide={onTemplateCreate} />
      <DisableTemplateModal show={disableModalShow} onHide={onTemplateDisable} template={modalTemplate} />

      <Row className="d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12} className="pb-3">
            <Card className="shadow">
                <Card.Body>
                    <h1>
                      {t('page.templates')}
                      <Button variant="primary" type="submit" style={{marginLeft: "0.6rem"}} onClick={() => setCreateModalShow(true)}>
                        {t('action.create')}
                      </Button>
                    </h1>

                    {templates.length > 0 && 
                        <ListGroup as="ul" key="templatesList">
                            {templates && templates.map((template) => {
                                return <TemplateListItem template={template} key={template.id} actions={userActions} />
                            })}
                        </ListGroup>
                    }

                    {templates.length < 1 && 
                        <div className="justify-content-center align-items-center d-flex">
                            <p style={{fontWeight: "bold"}}>{t('no_templates')}</p>
                        </div>
                    }
                </Card.Body>
            </Card>
        </Col>
    </Row>
    </>
    );
}

export default TemplateModule;