import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons'

function ProductListListItem(props) {
    const { t } = useTranslation();
    const { groupId } = useParams();

    const [show, setShow] = useState(false);

    const showActionsDropdown = (e)=>{
        setShow(!show);
    }
    
    const hideActionsDropdown = (e) => {
        setShow(false);
    }

    return (
    <ListGroup.Item as="li" className="align-items-start">
        <Row>
            <Col xs="8">
                <div className="fw-bold">
                    {/* {props.productlist.active &&
                    <Badge bg="success" style={{width: "1.6rem", fontSize: "0.7rem", paddingLeft: "0.3rem"}}>
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                    </Badge>}

                    {!props.productlist.active &&
                    <Badge bg="danger">
                        <FontAwesomeIcon icon={faXmark} size="lg" />
                    </Badge>}
                    {' '} */}

                    <span style={{lineBreak: "anywhere"}}>{props.productlist.name}{' '}</span>
                </div>

                {props.productlist.description}
            </Col>
            <Col xs="4" style={{textAlign: "right"}} >
                {props.productlist.active &&
                    <Button variant="light" className="mt-1" as={Link} to={"/groups/" + groupId + "/product-lists/" + props.productlist.id + "/products"}><FontAwesomeIcon icon={faBoxesStacked} /></Button>
                }
                <Button variant="light" className="mt-1" onMouseEnter={showActionsDropdown} onMouseLeave={hideActionsDropdown}>
                <NavDropdown title={<FontAwesomeIcon icon={faEllipsisVertical} size="lg" />} id="remove-dropdown-arrow" show={show}>
                    <NavDropdown.Item onClick={() => props.actions.details(props.productlist)}>{t('page.details')}</NavDropdown.Item>

                    {props.productlist.active &&
                        <>
                            <NavDropdown.Item onClick={() => props.actions.delete(props.productlist)}>{t('action.delete')}</NavDropdown.Item>
                        </>
                    }
                </NavDropdown>
                </Button>
            </Col>
            </Row>
    </ListGroup.Item>);
}

export default ProductListListItem;